import { NgModule } from '@angular/core';
import { ReversePipe } from './reverse';
import { SafeHtmlPipe } from './safe-html';
import { SafeResourceUrlPipe } from './safe-resource-url';
import { SafeUrlPipe } from './safe-url';
import { ShowTimePipe } from './show-time';

@NgModule({
    declarations: [
        SafeHtmlPipe,
        SafeUrlPipe,
        ShowTimePipe,
        ReversePipe,
        SafeResourceUrlPipe,
    ],
    imports: [],
    exports: [
        SafeHtmlPipe,
        SafeUrlPipe,
        ShowTimePipe,
        ReversePipe,
        SafeResourceUrlPipe,
    ],
})
export class PipesCustomModule {}
