import { Injectable } from '@angular/core';
import { NetworkService } from 'app/services/network-service.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class TrialEndedDialogService {
    constructor(private networkService: NetworkService) {
    }

    getPlanData(): Observable<any> {
        return this.networkService.get('plan');
    }

    sendPlanEmail(body: any): Observable<any> {
        return this.networkService.post('plan/email', body);
    }

    logout(): Observable<any> {
        return this.networkService.get('users/logout');
    }
}
