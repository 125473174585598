import {
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { data } from './signos-chart-data';

import { HistoryService } from '../../../history.service';
import { SignosChartService } from './signos-chart.service';
import { ChartOptions, ChartType } from 'chart.js';
import * as pluginAnnotations from 'chart.js';

@Component({
    selector: 'app-signos-chart',
    templateUrl: './signos-chart.component.html',
    styleUrls: ['./signos-chart.component.scss'],
})
export class SignosChartComponent implements OnInit, OnChanges {
    allData: any[] = [];
    options: any[] = [];
    selected: string;
    minYear = 2021;
    year: number = moment().year();

    posibleyears: number[] = [];
    month: number = 3;

    // chart
    multi: any[];
    view: any[];

    // options
    public lineChartData;
    public lineChartLabels = [];
    public lineChartOptions: ChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            // We use this empty structure as a placeholder for dynamic theming.
            xAxes: [{}],
            yAxes: [
                {
                    id: 'y-axis-0',
                    position: 'left',
                },
            ],
        },
        elements: {
            point: {
                radius: 5,
                hitRadius: 5,
                hoverRadius: 5,
                hoverBorderWidth: 2,
                backgroundColor: 'rgba(13, 165, 0, 1)',
            },
        },
    };
    public lineChartColors = [];
    public lineChartLegend = false;
    public lineChartType: ChartType = 'line';
    public lineChartPlugins = [pluginAnnotations];

    @Input() signosVitales: any;
    @Input() paciente: any;
    @Input() selectedSign: any;
    // @ViewChild('chart', { static: false }) chart: any;

    constructor(
        private historyService: HistoryService,
        private route: ActivatedRoute // private signosChartService: SignosChartService
    ) {}

    ngOnInit(): void {
        if (this.signosVitales) {
            this.view = [undefined, 350];
        } else if (this.selectedSign) {
            this.view = [280, 200];
        }
        this.populateYears();
        this.getChartValues();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.selectedSign) {
            this.cambiarOpcion(this.selected);
        }
    }

    populateYears(): void {
        for (let i = this.minYear; i <= this.year; i++) {
            this.posibleyears.push(i);
        }
    }

    getChartValues(monthCount?: number): void {
        if (monthCount && monthCount === this.month) {
            return;
        } else if (monthCount && monthCount !== this.month) {
            this.month = monthCount;
        }

        const today = `${this.year}-${moment().month() + 1}-${moment().date()}`;
        const desde = moment(
            moment(new Date(today)).subtract(this.month, 'month')
        )
            .add(1, 'days')
            .format('YYYY-MM-DD');
        const hasta = moment(new Date(today))
            .add(1, 'days')
            .format('YYYY-MM-DD');
        this.getSignoVitalesChart(
            this.route.snapshot.paramMap.get('paciente_id') || this.paciente,
            desde,
            hasta
        );
    }

    // ngAfterViewInit() {
    //     this.signosChartService.showDots(this.chart);
    // }

    getSignoVitalesChart(paciente, desde, hasta): void {
        this.historyService
            .getSignoVitalesChart(paciente, desde, hasta)
            .subscribe((res) => {
                this.options = [];
                res.sort(
                    (a, b) =>
                        a.series[a.series.length - 1].name <
                        b.series[b.series.length - 1].name
                );
                res.forEach((element) => {
                    if (!element.name.includes(' 2')) {
                        this.options.push(element.name);
                    }
                });
                res.forEach((element) => {
                    element.series.forEach((serie) => {
                        serie.name = moment(serie.name).format('DD/MM/YYYY');
                        // serie.name = `${moment(serie.name)
                        //     .locale('es')
                        //     .format('ll')} ${moment(serie.name)
                        //     .locale('es-do')
                        //     .format('LTS')}`;
                    });
                });
                if (this.selected === undefined) {
                    this.selected = res[0].name;
                }
                this.allData = res;
                this.cambiarOpcion(this.selected);
            });
    }

    cambiarOpcion($event: string): void {
        this.selected = $event;
        if (this.selectedSign) {
            this.multi = this.allData.filter((ele) =>
                ele.name.includes(this.selectedSign.descripcion)
            );
        } else {
            this.multi = this.allData.filter((ele) =>
                ele.name.includes($event)
            );
        }

        if (this.multi[0]) {
            this.lineChartData = [
                {
                    data: this.multi[0].series.map((ele) => ele.value),
                    lineTension: 0,
                },
            ];
            this.lineChartLabels = this.multi[0].series.map((ele) =>
                this.selectedSign
                    ? ele.name.substring(
                          ele.name.indexOf('/') + 1,
                          ele.name.length
                      )
                    : ele.name
            );
        }
    }

    getImage(val) {
        switch (val) {
            case '5bd20866-3478-53f7-bc79-d2aae304b443':
                return 'assets/images/signos-vitales/oximetria_color.png';
            case 'ff6c5a08-b2fa-56b6-ac00-6830f4074b6a':
                return 'assets/images/signos-vitales/tension_arterial_color.png';
            case '334b6b31-12a2-5bfc-bf4f-870c0954b343':
                return 'assets/images/signos-vitales/talla_color.png';
            case 'afca48e9-59ea-5a28-b8a4-88e0cc6eda1c':
                return 'assets/images/signos-vitales/frecuencia_cardiaca_color.png';
            case '8611ccd6-fa12-11de-ab16-001a64a3e00a':
                return 'assets/images/signos-vitales/sato2_color.png';
            case 'e3818706-97a7-11e0-bc2e-00215edb8b08':
                return 'assets/images/signos-vitales/presion_arterial_media_color.png';
            case '6f2acb30-fa12-11de-8e55-001a64a3e00a':
                return 'assets/images/signos-vitales/presion_venosa_central_color.png';
            case '1026a71e-97a8-11e0-a284-00215edb8b08':
                return 'assets/images/signos-vitales/presion_intracraneal_color.png';
            case 'c6577abe-8c0f-5a02-ac84-aa94c2247b1e':
                return 'assets/images/signos-vitales/frecuencia_respiratoria_color.png';
            case '23de214c-97a8-11e0-9c1d-00215edb8b08':
                return 'assets/images/signos-vitales/presion_arterial_pulmonar_color.png';
            case 'b80c8fef-a677-5340-85fb-2c162d75df03':
                return 'assets/images/signos-vitales/peso_color.png';
            case 'c093c7f6-6edf-595e-9539-70de788efbaa':
                return 'assets/images/signos-vitales/temperatura_color.png';
            case 'e287dd64-e392-11e0-a559-00215edb8b08':
                return 'assets/images/signos-vitales/glicemia_capilar_insulina_color.png';
            case 'fa80d442-f1e4-11e0-ae4c-00215edb8b08':
                return 'assets/images/signos-vitales/evacuaciones_color.png';
            case '20ae4faa-f1e5-11e0-8d10-00215edb8b08':
                return 'assets/images/signos-vitales/vomito_color.png';
            case 'e1e5b788-f429-5a32-9f7e-63865921a5e5':
                return 'assets/images/signos-vitales/masa_muscular.png';
            case '143e3022-c580-11eb-a051-0050562d2f03':
                return 'assets/images/signos-vitales/grado.png';
        }
    }

    hideSignos(descrip: string): boolean {
        for (const i of this.allData) {
            if (i.name.includes(descrip)) {
                return true;
            }
        }

        return false;
    }
}
