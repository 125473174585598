import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
import * as moment from 'moment';

/**
 * Generated class for the SafeHtmlPipe pipe.
 *
 * See https://angular.io/api/core/Pipe for more info on Angular Pipes.
 */
@Pipe({
    name: 'showTime',
})
export class ShowTimePipe implements PipeTransform {

    constructor(private sanitizer: DomSanitizer) { }

    transform(minutes: number) {
        var h = Math.floor(minutes / 60);
        var m = minutes % 60;
        var h_aux = "";
        var m_aux = "";

        if (h.toString().length == 1) {
            h_aux = "0" + h.toString();
        } else {
            h_aux = h.toString();
        }

        if (m.toString().length == 1) {
            m_aux = "0" + m.toString();
        } else {
            m_aux = m.toString();
        }
        return h_aux + 'H:' + m_aux + ' min';
    }

}