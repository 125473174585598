import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import {
    MatButtonModule,
    MatIconModule,
    MatPaginatorIntl,
    MAT_DATE_LOCALE,
} from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import {
    FuseProgressBarModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,
} from '@fuse/components';
import { fuseConfig } from 'app/fuse-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { SessionInterceptor } from './guards/interceptors/session.interceptor';
import { AuthGuard } from './guards/auth.guard';
import { PipesCustomModule } from './pipes/pipes.module';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { PacienteGuard } from './guards/paciente.guard';
import { IqGuard } from './guards/iq.guard';
import { ColorPickerModule } from 'ngx-color-picker';
// Custom paginator
import { MatPaginatorIntlCro } from './custom/custom-mat-paginator.int';
import { JitsiService } from './main/telemedicine/jitsi.service';
import { TelemedicineService } from './main/telemedicine/telemedicine.service';
import { FilePreviewDialogModule } from './main/telemedicine/chat/chat-view/file-preview-dialog/file-preview-dialog.module';
import { NgxStripeModule } from 'ngx-stripe';

const appRoutes: Routes = [
    {
        path: 'pacientes',
        loadChildren: () =>
            import('./main/patient/patient.module').then(
                (m) => m.PatientModule
            ),
        canActivate: [AuthGuard],
    },
    {
        path: 'admin',
        loadChildren: () =>
            import('./main/admin/admin.module').then((m) => m.AdminModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'portal',
        loadChildren: () =>
            import('./main/portal/portal.module').then((m) => m.PortalModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'atencion',
        loadChildren: () =>
            import('./main/secretary/secretary.module').then(
                (m) => m.SecretaryModule
            ),
        canActivate: [AuthGuard],
    },
    {
        path: 'busqueda',
        loadChildren: () =>
            import('./main/busqueda/busqueda.module').then(
                (m) => m.BusquedaModule
            ),
        canActivate: [AuthGuard],
    },
    {
        path: 'sales',
        loadChildren: () =>
            import('./main/sales/sales.module').then((m) => m.SalesModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'consulta',
        loadChildren: () =>
            import('./main/telemedicine/telemedicine.module').then(
                (m) => m.TelemedicineModule
            ),
    },
    {
        path: 'medic/history/:paciente_id/:cita_id/:view_id/:consulta_id/:om_id',
        loadChildren: () =>
            import('./main/medic/history/history.module').then(
                (m) => m.HistoryModule
            ),
        canActivate: [AuthGuard, PacienteGuard, IqGuard],
    },
    {
        path: '',
        loadChildren: () =>
            import('./main/auth/auth.module').then((m) => m.AuthModule),
    },
    { path: '**', redirectTo: '', pathMatch: 'full' },
];
registerLocaleData(es);
@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, { useHash: true }),
        TranslateModule.forRoot(),

        // Color Picker Module
        ColorPickerModule,

        // Material moment date module
        MatMomentDateModule,

        //Stripe
        NgxStripeModule.forRoot('pk_test_2zeECGWMDvJcw7USm8CGS9mM00yZxRiVqO'),

        // Material
        MatButtonModule,
        MatIconModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,

        // Pipes Custom Module
        PipesCustomModule,
        FilePreviewDialogModule,
    ],
    bootstrap: [AppComponent],
    providers: [
        { provide: MatPaginatorIntl, useClass: MatPaginatorIntlCro },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: SessionInterceptor,
            multi: true,
        },
        {
            provide: MAT_DATE_LOCALE,
            useValue: 'en-GB',
        },
        { provide: LOCALE_ID, useValue: 'es-*' },
        TelemedicineService,
    ],
})
export class AppModule {}
