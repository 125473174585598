import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PipesCustomModule } from 'app/pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
// Material
import {
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatSelectModule,
    MatSortModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatDividerModule,
    MatTabsModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatButtonToggleModule,
    MatMenuModule,
    MatDatepickerModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatDialogModule,
} from '@angular/material';
import { MenuIzquierdoModule } from 'app/main/medic/history/menu_izquierdo/menu_izquierdo.module';
// Componentes

import { ConfirmationDialogComponent } from './confirmation-dialog.component';

@NgModule({
    declarations: [ConfirmationDialogComponent],
    imports: [
        CommonModule,
        RouterModule,
        PipesCustomModule,
        TranslateModule,
        FuseSharedModule,
        MenuIzquierdoModule,

        // Material
        MatFormFieldModule,
        MatInputModule,
        MatTableModule,
        MatPaginatorModule,
        MatSelectModule,
        MatSortModule,
        MatIconModule,
        MatButtonModule,
        MatTooltipModule,
        MatSnackBarModule,
        MatDividerModule,
        MatTabsModule,
        MatChipsModule,
        MatAutocompleteModule,
        MatButtonToggleModule,
        MatMenuModule,
        MatDatepickerModule,
        MatRadioModule,
        MatCheckboxModule,
        MatSlideToggleModule,
        MatListModule,
        MatGridListModule,
        MatCardModule,

        MatDialogModule,
    ],
    exports: [ConfirmationDialogComponent],
    entryComponents: [ConfirmationDialogComponent],
})
export class ConfirmationDialogModule {}
