import { FuseConfig } from '@fuse/types';
import { environment as env } from '../../environments/environment';

/**
 * Default Fuse Configuration
 *
 * You can edit these options to change the default options. All these options also can be
 * changed per component basis. See `app/main/pages/authentication/login/login.component.ts`
 * constructor method to learn more about changing these options per component basis.
 */

export const fuseConfig: FuseConfig = {
    // Color themes can be defined in src/app/app.theme.scss
    colorTheme: 'theme-default',
    customScrollbars: true,
    layout: {
        style: 'vertical-layout-1',
        width: 'fullwidth',
        navbar: {
            primaryBackground: 'fuse-navy-50',
            secondaryBackground: 'fuse-navy-50',
            folded: true,
            hidden: true,
            position: 'left',
            variant: 'vertical-style-1',
        },
        toolbar: {
            customBackgroundColor: true,
            background: 'fuse-white-500',
            hidden: false,
            position: 'above',
        },
        footer: {
            customBackgroundColor: true,
            background: 'fuse-white-100',
            hidden: true,
            position: 'below-fixed',
        },
        sidepanel: {
            hidden: false,
            position: 'right',
        },
    },
    URL: !env.production
        ? '/app/api/v1/'
        : '/app/api/v1/',
    API_KEY:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJrZXkiOiJkOWMwZDNkYS00NTAxLTU0ODgtYmNiYy0xMzdmNzRhZDJmYTAifQ.TGVOibek6Fsh_6sL-8sAjeC7Lv_0mFPm0704BDaAJz0',
    MAPS_API_KEY: 'AIzaSyB7TYbuacrz8GpPHePknla3A5tUbFy9jPM',
    isMedic: false,
    isAdmin: false,
    isSecretary: false,
    isPatient: false,
    user: {
        id: '',
        id_paciente: '',
        firstname: 'Cargando. . .',
        lastname: '',
        email: '',
        image: 'assets/images/avatars/profile.jpg',
        especialidades: [],
        ambulatorio: [],
    },
    modules: {
        modulo_telemedicina: false,
        modulo_historia_medica: false,
        modulo_control_citas: false,
        modulo_ventas: false,
    },
    moneda: {
        id: 0,
        nombre: '',
        iso: '',
        simbolo: '',
    },
    formas_pago: [],
    impuesto_nombre: '',
    impuesto_porcentaje: 0,
    timezone: '',
    plan: 0,
    dias_restantes: 0,
    modo_prueba: false,
    floating_videocall: false,
    jitsiActivated: env.production ? false : true,
    responsable: {},
};
