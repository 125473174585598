import {
    Component,
    OnInit,
    ChangeDetectorRef,
    EventEmitter,
    Output,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HistoryService } from '../../history.service';
import * as moment from 'moment';
import { elementAt } from 'rxjs/operators';

@Component({
    selector: 'app-antecedentes',
    templateUrl: './antecedentes.component.html',
    styleUrls: ['./antecedentes.component.scss'],
})
export class AntecedentesComponent implements OnInit {
    // Variables publicas
    public data: any;

    @Output('results') results = new EventEmitter();

    constructor(
        private historyService: HistoryService,
        private route: ActivatedRoute,
        private router: Router,
        private cdf: ChangeDetectorRef
    ) {
        this.data = {
            antecedentes: [],
            antecedentes_patologicos: [],
            antecedentes_no_patologicos: [],
        };
    }
    ngOnInit() {
        const paciente_id = this.route.snapshot.paramMap.get('paciente_id');

        this.obtenerAntecedentes(paciente_id);

        this.historyService.subscribe((res) => {
            if (res === 'listar_antecedentes') {
                this.obtenerAntecedentes(paciente_id);
            }
        });
    }

    obtenerAntecedentes(paciente_id) {
        this.historyService.getAntecedentes(paciente_id).subscribe(
            (res) => {
                this.data.antecedentes = [];
                this.data.antecedentes_patologicos = [];
                this.data.antecedentes_no_patologicos = [];
                res.forEach((element) => {
                    this.data.antecedentes.push({
                        id: element.id,
                        fecha: moment.utc(element.fecha).format('DD/MM/YYYY'),
                        tipo: element.tipo,
                        importante: element.importante,
                        observacion: element.observacion,
                        orden: element.orden,
                        medico: element.medico,
                        valor:
                            (element.importante == 0
                                ? '<span style="font-size: 10px;">' +
                                  element.tipo.abreviacion +
                                  '</span>'
                                : element.importante == 1
                                ? '<span style="font-size: 10px;">' +
                                  element.tipo.abreviacion +
                                  '</span>'
                                : '???') +
                            '<b style="font-weight: normal; font-size: 10px;">' +
                            ' - ' +
                            element.observacion +
                            '</b>' +
                            (element.fecha == undefined ||
                            element.fecha == null ||
                            element.fecha == ''
                                ? ''
                                : '<span style="font-size: 10px;"> - ' +
                                  moment
                                      .utc(element.fecha)
                                      .format('DD/MM/YYYY')) +
                            '</span>',
                    });
                    if (element.importante) {
                        this.data.antecedentes_patologicos.push({
                            id: element.id,
                            fecha: moment
                                .utc(element.fecha)
                                .format('DD/MM/YYYY'),
                            tipo: element.tipo,
                            importante: element.importante,
                            observacion: element.observacion,
                            orden: element.orden,
                            medico: element.medico,
                            valor:
                                (element.importante == 0
                                    ? '<span style="font-size: 10px;">' +
                                      element.tipo.abreviacion +
                                      '</span>'
                                    : element.importante == 1
                                    ? '<span style="font-size: 10px;">' +
                                      element.tipo.abreviacion +
                                      '</span>'
                                    : '???') +
                                '<b style="font-weight: normal; font-size: 10px;">' +
                                ' - ' +
                                element.observacion +
                                '</b>' +
                                (element.fecha == undefined ||
                                element.fecha == null ||
                                element.fecha == ''
                                    ? ''
                                    : '<span style="font-size: 10px;"> - ' +
                                      moment
                                          .utc(element.fecha)
                                          .format('DD/MM/YYYY')) +
                                '</span>',
                        });
                    }

                    if (!element.importante) {
                        this.data.antecedentes_no_patologicos.push({
                            id: element.id,
                            fecha: moment
                                .utc(element.fecha)
                                .format('DD/MM/YYYY'),
                            tipo: element.tipo,
                            importante: element.importante,
                            observacion: element.observacion,
                            orden: element.orden,
                            medico: element.medico,
                            valor:
                                (element.importante == 0
                                    ? '<span style="font-size: 10px;">' +
                                      element.tipo.abreviacion +
                                      '</span>'
                                    : element.importante == 1
                                    ? '<span style="font-size: 10px;">' +
                                      element.tipo.abreviacion +
                                      '</span>'
                                    : '???') +
                                '<b style="font-weight: normal; font-size: 10px;">' +
                                ' - ' +
                                element.observacion +
                                '</b>' +
                                (element.fecha == undefined ||
                                element.fecha == null ||
                                element.fecha == ''
                                    ? ''
                                    : '<span style="font-size: 10px;"> - ' +
                                      moment
                                          .utc(element.fecha)
                                          .format('DD/MM/YYYY')) +
                                '</span>',
                        });
                    }
                });

                if (this.data.antecedentes.length > 0) this.results.emit(true);
                this.cdf.detectChanges();
            },
            (err) => {
                // console.log(err);
            }
        );
    }

    agregarNuevoAntecedente() {
        const paciente_id = this.route.snapshot.paramMap.get('paciente_id');
        const cita_id = this.route.snapshot.paramMap.get('cita_id');
        const consulta_id = this.route.snapshot.paramMap.get('consulta_id');
        const om_id = this.route.snapshot.paramMap.get('om_id');
        // this.router.navigate(['medic/history/' + paciente_id + '/' + cita_id + '/agregar_alergia']);
        this.router.navigate([
            '/medic/history/' +
                paciente_id +
                '/' +
                cita_id +
                '/agregar_antecedentes' +
                '/' +
                consulta_id +
                '/' +
                om_id,
        ]);
    }
}
