import {
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output,
    ViewEncapsulation,
    ViewChild,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseProgressBarService } from '../progress-bar/progress-bar.service';
import { BusquedaService } from 'app/main/busqueda/busqueda.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { locale as es } from './i18n/es';
import { locale as en } from './i18n/en';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { SearchBarService } from './search-bar.service';

@Component({
    selector: 'fuse-search-bar',
    templateUrl: './search-bar.component.html',
    styleUrls: ['./search-bar.component.scss'],
})
export class FuseSearchBarComponent implements OnInit, OnDestroy {
    collapsed: boolean;
    fuseConfig: any;

    @Output()
    input: EventEmitter<any>;

    // Private
    private _unsubscribeAll: Subject<any>;

    options: any = [];
    dummy_options: any[] = [];
    subscription: any;
    noResults: boolean = false;

    @ViewChild('myInput', { static: true }) inputEl;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private service: BusquedaService,
        private fuseProgressBarService: FuseProgressBarService,
        private router: Router,
        private translate: TranslateService,
        private translationLoaderService: FuseTranslationLoaderService,
        private searchBarService: SearchBarService
    ) {
        this.translationLoaderService.loadTranslations(es, en);
        // Set the defaults
        this.input = new EventEmitter();
        this.collapsed = false;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                this.fuseConfig = config;
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Collapse
     */
    // collapse(): void
    // {
    //     this.collapsed = true;
    // }

    /**
     * Expand
     */
    // expand(): void
    // {
    //     this.collapsed = false;
    // }

    /**
     * Search
     *
     * @param event
     */
    search(event): void {
        event.target.value = event.target.value.replace(
            /[&\/\\#,+()$~%.'":*?<>{}]/g,
            ''
        );
        if (event.target.value !== '') {
            if (this.subscription !== undefined) {
                this.subscription.unsubscribe();
            }
            if (event.target.value.trim().length > 2) {
                this.dummy_options = [];
                this.fuseProgressBarService.show();
                this.subscription = this.service
                    .getBusqueda(event.target.value.trim())
                    .subscribe((res) => {
                        if (this.fuseConfig.isPatient) {
                            res.forEach((element, i) => {
                                const ele = { ...element };
                                ele.registros = [];

                                res[i].registros.forEach((reg) => {
                                    if (
                                        reg.paciente ===
                                        this.fuseConfig.user.id_paciente
                                    ) {
                                        ele.registros.push(reg);
                                    }
                                });

                                ele.cantidad = ele.registros.length;
                                if (ele.registros.length > 0) {
                                    this.dummy_options.push(ele);
                                }
                            });
                        } else {
                            this.dummy_options = [...res];
                        }

                        if (this.dummy_options.length > 0) {
                            this.options = [...this.dummy_options];
                            this.noResults =
                                this.dummy_options.length > 0 ? false : true;
                        } else {
                            let fakeRes = [
                                {
                                    tipo: this.translate.instant('NO_RESULTS'),
                                    cantidad: -1,
                                    endpoint: null,
                                    registros: null,
                                },
                            ];
                            this.options = [...fakeRes];
                        }
                        this.fuseProgressBarService.hide();
                    });
            } else {
                this.fuseProgressBarService.hide();
                this.options = [];
            }
        }

        // this.input.emit(event.target.value);
    }

    delete(event): void {
        if (event.target.value.trim().length <= 2) {
            this.fuseProgressBarService.hide();
            this.options = [];
        }
    }

    displayFn(registro?: Registro): string | undefined {
        return registro
            ? registro.id != ''
                ? registro.informacion
                : undefined
            : undefined;
    }

    selected(evt) {
        if (evt.option.value.cantidad === -1) return;
        if (evt.option.value.obj === null) {
            this.searchBarService.data = [...this.options];
            console.log(this.searchBarService);
            this.router.navigate(['busqueda']);
        } else {
            const params = window.location.hash.split('/');
            if (evt.option.value.EP === '/cita') {
                window.open(
                    `${params[0]}/medic/history/${evt.option.value.obj.paciente}/${evt.option.value.obj.cita}/citas/${evt.option.value.obj.tipo_cita}/${evt.option.value.obj.cita}`,
                    '_blank'
                );
            } else {
                window.open(
                    `${params[0]}/portal/paciente/update/${evt.option.value.obj.paciente}`,
                    '_blank'
                );
            }
        }
    }

    clearSearch() {
        if (this.subscription != undefined) {
            this.subscription.unsubscribe();
        }
        this.inputEl.nativeElement.value = '';
        this.fuseProgressBarService.hide();
        this.options = [];
    }
}

export interface Registro {
    id: string;
    texto: string;
    informacion: string;
}

export interface Option {
    tipo: string;
    cantidad: number;
    endpoint: string;
    registros: Array<Registro>;
}
