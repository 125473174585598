export const locale = {
    lang: 'en',
    data: {
        LOGIN: {
            TITLE: 'LOGIN TO YOUR ACCOUNT',
            USER: 'User (patient) / Email',
            REQUIRED: 'Required field',
            DO_NOT_MATCH: 'The passwords do not match',
            MIN_ERROR: 'Must have a minimun of 6 characters',
            USER_REQUIRED: 'User required',
            PASSWORD: 'Password',
            NEW_PASSWORD: 'New password',
            CONFIRM_PASSWORD: 'Confirm password',
            REMEMBER_ME: 'Remember me',
            LOGIN: 'LOGIN',
            EMAIL_REQUIRED: 'Email is required!',
            EMAIL: 'Email',
            PASSWORD_REQUIRED: 'Password is required!',
            WELCOME: 'Welcome to BINWUS-HIS!',
            LOADING: 'Cargando...',
            VALID_EMAIL: 'Please enter a valid email address',
            INVALID_PASSWORD: 'Invalid password',
            BACK: 'BACK',
            DONT_HAVE: 'Dont have an account?',
            REGISTER: 'Register ',
            GOOGLE: 'Login with Google account',
            PASSWORD_RECOVERY: 'Password recovery',
            FORGOT_PASSWORD: 'Forgot your password?',
            RESET_PASSWORD: 'RESET PASSWORD',
            LOG_MESSAGE: 'Login with your user and password',
            DEVELOPED_BY_MSG: 'Developed by BINWUS',
            INACTIVE_ACCOUNT:
                'Your account is not active. Please contact the administrator. Email: support@portalmedico.com',
            CHECK_CONNECTION:
                'Please check your connection, wait a moment and try again.',
        },
    },
};
