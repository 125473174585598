import {
    AfterContentChecked,
    Component,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { NavigationEnd, Router } from '@angular/router';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseConfigService } from '@fuse/services/config.service';
import { TranslateService } from '@ngx-translate/core';
import { IngresoService } from 'app/main/medic/history/ingreso/ingreso.service';
import { ActiveCallDialogComponent } from 'app/main/medic/history/ingreso/modals/active-call-dialog/active-call-dialog.component';
import { PortalService } from 'app/main/portal/portal.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NavigateAppointmentDialogComponent } from '../navigate-appointment-dialog/navigate-appointment-dialog.component';
import { TrialEndedDialogComponent } from '../trial-ended-dialog/trial-ended-dialog.component';

@Component({
    selector: 'app-toolbar-menu',
    templateUrl: './toolbar-menu.component.html',
    styleUrls: ['./toolbar-menu.component.scss'],
})
export class ToolbarMenuComponent implements OnInit, OnDestroy {
    selectedOption = '';
    appRoute = '';
    expired: boolean = false;
    fuseConfig: any;
    model: any;

    private _unsubscribeAll = new Subject();

    @Input() type: 'row' | 'column';

    constructor(
        private _fuseConfigService: FuseConfigService,
        private router: Router,
        private translate: TranslateService,
        private portalService: PortalService,
        private matDialog: MatDialog,
        private snackBar: MatSnackBar,
        private _fuseSidebarService: FuseSidebarService,
        private ingresoService: IngresoService
    ) {
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((settings) => {
                this.fuseConfig = settings;
                if (
                    this.fuseConfig.modo_prueba &&
                    this.fuseConfig.dias_restantes <= 0 &&
                    this.getUrl() !== '' &&
                    !this.expired
                ) {
                    this.expired = true;
                    const firstDiag = this.matDialog.open(
                        TrialEndedDialogComponent,
                        {
                            // width: '600px',
                            width: '98%',
                            // height: '500px',
                            height: '98%',
                            disableClose: true,
                            data: {
                                emailSent: false,
                            },
                        }
                    );

                    // firstDiag.afterClosed().subscribe(data => {
                    //     const secondDiag = this.matDialog.open(TrialEndedDialogComponent, {
                    //         // width: '600px',
                    //         width: '600px',
                    //         // height: '500px',
                    //         height: '530px',
                    //         disableClose: true,
                    //         data: {
                    //             emailSent: data ? true : false
                    //         }
                    //     });
                    // });
                }
            });
        this.ingresoService.model
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((data) => {
                this.model = data;
            });
        this.router.events.forEach((event) => {
            if (event instanceof NavigationEnd) {
                this.ngOnInit();
            }
        });
    }

    ngOnInit(): void {
        const params = window.location.hash.split('/');
        this.menuOptionSelected(params);
    }

    menuOptionSelected(urlParams: string[]): void {
        switch (urlParams[1]) {
            case 'medic':
                this.appRoute = 'portal';
                this.selectedOption = '';
                break;

            case 'sales':
                this.selectedOption = 'sales';
                break;

            case 'portal':
                this.appRoute = 'portal';
                switch (urlParams[2]) {
                    case 'home':
                        this.selectedOption = 'home';
                        break;
                    case 'paciente':
                        this.selectedOption = 'pacientes';
                        break;

                    case 'atender_calendario':
                        this.selectedOption = 'citas';
                        break;

                    case 'waiting_list':
                        this.selectedOption = 'listadeespera';
                        break;

                    case 'informes':
                        this.selectedOption = 'informe';
                        break;

                    case 'config':
                        this.selectedOption = 'config';
                        break;

                    case 'profile':
                        this.selectedOption = 'profile';
                        break;
                }
                break;

            case 'pacientes':
                this.appRoute = 'pacientes';
                switch (urlParams[2]) {
                    case 'home':
                        this.selectedOption = 'home';
                        break;
                    case 'medics':
                        this.selectedOption = 'medicos';
                        break;
                    case 'informes':
                        this.selectedOption = 'informe';
                        break;
                    case 'recipes':
                        this.selectedOption = 'recipe';
                        break;
                    default:
                        this.selectedOption = '';
                        break;
                }
                break;

            case 'atencion':
                this.appRoute = 'atencion';
                switch (urlParams[2]) {
                    case 'home':
                        this.selectedOption = 'home';
                        break;
                    case 'paciente':
                        this.selectedOption = 'pacientes';
                        break;
                    case 'atender_calendario':
                        this.selectedOption = 'citas';
                        break;
                    case 'informes':
                        this.selectedOption = 'informe';
                        break;
                    case 'config':
                        this.selectedOption = 'config';
                        break;
                }
                break;

            case 'auth':
                switch (urlParams[2]) {
                    case 'login':
                        this.selectedOption = 'home';
                        break;
                    case 'login':
                        this.appRoute = 'pacientes';
                        break;
                }
                break;

            case 'admin':
                this.appRoute = 'admin';
                switch (urlParams[2]) {
                    case 'home':
                        this.selectedOption = 'home';
                        break;
                    case 'clinic-list':
                        this.selectedOption = 'clinic-list';
                        break;
                }
                break;

            default:
                this.selectedOption = '';
                if (this.fuseConfig.isMedic) {
                    this.appRoute = 'portal';
                } else if (this.fuseConfig.isPatient) {
                    this.appRoute = 'pacientes';
                } else if (this.fuseConfig.isSecretary) {
                    this.appRoute = 'atencion';
                } else if (this.fuseConfig.isAdmin) {
                    this.appRoute = 'admin';
                }
                break;
        }
    }

    changeSrc(e: any): void {
        const nodes = e.target.firstChild.children;
        const src = nodes[0].src;

        if (
            src.includes('gris') &&
            !src.includes(this.selectedOption) &&
            !e.target.disabled
        ) {
            e.target.firstChild.firstChild.src = src.replace('gris', 'color');
            e.target.firstChild.children[2].style.height = '3px';
        } else if (!src.includes(this.selectedOption) && !e.target.disabled) {
            e.target.firstChild.firstChild.src = src.replace('color', 'gris');
            e.target.firstChild.children[2].style.height = '0';
        }
    }

    openDialog(url): void {
        // Verifica que esta en la historia medica
        if (this.appRoute === 'portal' && this.selectedOption === '') {
            // Opciones al salir de cita
            const dialog = this.matDialog.open(
                NavigateAppointmentDialogComponent,
                {
                    width: '420px',
                    panelClass: 'custom-dialog',
                    autoFocus: false,
                    data: this.model,
                }
            );

            const subs = dialog.afterClosed().subscribe(async (res) => {
                if (typeof res === 'string') {
                    switch (res) {
                        case 'delete':
                            try {
                                const params = window.location.hash.split('/');
                                const response = await this.portalService
                                    .deleteCitaData(params[4])
                                    .toPromise();
                                this.snackBar.open(response.message, '', {
                                    verticalPosition: 'top',
                                    duration: 3000,
                                    panelClass: 'custom-green',
                                });
                                this.navigate(url);
                            } catch (err) {
                                console.log(err);
                                // this.snackBar.open(err.error.message, '', {
                                //     verticalPosition: 'top',
                                //     duration: 3000,
                                //     panelClass: 'custom-red',
                                // });
                            }
                            break;

                        default:
                            this.navigate(url);
                            break;
                    }
                }
                subs.unsubscribe();
            });
        } else {
            this.navigate(url);
        }
    }

    navigate(url: string): void {
        if (this.type === 'column') {
            this._fuseSidebarService.getSidebar('menu').toggleOpen();
        }
        this.router.navigate([`${this.appRoute}/${url}`]);
    }

    getUrl(): string {
        const url = window.location.hash.split('#');
        return url[1] === undefined ? '' : url[1].substring(1);
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
