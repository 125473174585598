export const locale = {
    lang: 'es',
    data: {
        NAV: {
            SALES_TITLE: 'Ventas',
            PROFILE: {
                TITLE: 'Perfil',
                BADGE: '25',
            },
            HOME: {
                TITLE: 'Principal',
                BADGE: '25',
            },
            MEDIC_MENU: 'Médico',
            ADMIN_MENU: 'Administrador',
            PATIENTS_OF_THE_DAY: {
                TITLE: 'Pacientes del Día',
            },
            PRESUPUESTO: {
                TITLE: 'Presupuesto',
            },
            HONORARIOS: {
                TITLE: 'Honorarios',
            },
            AGENDA: {
                TITLE: 'Agenda Médica',
            },
            HOSPITALIZATION: {
                TITLE: 'Hospitalización',
            },
            LOGOUT: {
                TITLE: 'Desconectarse',
                MESSAGE: '¿Estás seguro que quiere desconectarse?',
                YES: 'Sí',
                NO: 'No',
            },
            PATIENTS: {
                TITLE: 'Pacientes',
            },
            CALENDAR: {
                TITLE: 'Calendario',
            },
            PORTAL: {
                HOME: 'Principal',
                PATIENT: 'Pacientes',
                ATTEND: 'Citas',
                WAITING_LIST: 'Lista de espera',
                REPORT: 'Informes',
                CONFIG: 'Configuración',
                CLINICS: 'Clínicas',
                // Msg
                HOME_MSG: 'Mi vista princial',
                PATIENT_MSG: 'Ubicar a mis pacientes',
                ATTEND_MSG: 'Ver mi agenda',
                WAITING_LIST_MSG: 'Mi lista para telemedicina',
                REPORT_MSG: 'Informes de mis pacientes',
                CONFIG_MSG: 'Ajustes a mi configuración',
                CLINICS_MSG: 'Lista de clínicas',
                MY_APPOINTMENTS: 'Citas',
                MY_MEDICS: 'Médicos',
                MY_RECIPES: 'Recipes',
                MY_REPORTS: 'Informes',
                HISTORY: 'Historia',
                // Blocked
                HOME_BLOCKED:
                    'No disponible, solicite la activación del módulo Telemedicina, Control de Citas o Historia clínica.',
                PATIENT_BLOCKED:
                    'No disponible, solicite la activación del módulo Telemedicina, Control de Citas o Historia clínica.',
                ATTEND_BLOCKED:
                    'No disponible, solicite la activación del módulo Control de Citas.',
                WAITING_LIST_BLOCKED:
                    'No disponible, solicite la activación del módulo Telemedicina.',
                REPORT_BLOCKED:
                    'No disponible, solicite la activación del módulo Historia clínica.',
                CONFIG_BLOCKED:
                    'No disponible, solicite la activación de módulo: Telemedicina, Control de Citas o Historia clínica.',
            },
            SALES: {
                CLIENTS: 'Clientes',
                INVENTORY: 'Artículos',
                TAX: 'Impuestos',
                UNIT: 'Unidad',
            },
        },
        BTN: {
            CLOSE: 'Cerrar',
            CONTINUE: 'Continuar',
            CANCEL: 'Cancelar',
            DELETE: 'Eliminar',
            EXIT: 'Salir',
            CONTINUE_ATTENDING: 'Continuar atención',
            SAVE_EXIT: 'Guardar y salir',
        },
        DIALOG: {
            MEDIC_APPOINTMENT_TITLE: 'Cerrar atención de cita',
            LOSE_CHANGES:
                'Perderá todos los cambios aplicados hasta el momento. \n ¿Desea continuar?',
            BODY: 'Está por abandonar la atención de la cita actual. \n ¿Que acción desea tomar?',
        },
        BLOCKED_MSG:
            'El período de prueba ha expirado, para suscribirse a un plan contactenos a ventas@portalmedico.com',
        TRIAL_ENDED: {
            TITLE: 'PM+ Suscripción Plan',
            MSG_LINE1:
                'El período de prueba ha finalizado, debe suscribirse a un plan.',
            MSG_LINE2: 'Por favor pongase en contacto con',
            MSG_LINE3: 'para activar un plan.',
            SEND_MAIL: 'Enviar correo',
            FIRST_SUBHEADER:
                'Tu período de prueba ha finalizado, debes suscribirte a un plan.',
            MONTH_SUS: 'Suscripción mensual',
            ADDITIONAL_MED: 'Médico adicional',
            MORE_THAN: 'Más de',
            MEDICS: 'médicos',
            SUBSCRIBE: 'Suscribirme',
            COMPARE_PLANS_SPECS: 'Compare los planes y características',
            SPECS: 'Características',
            CONTACT: 'Contactar',
            REQ_REC:
                'Hemos recibido tu solicitud, serás contactado por el area de ventas.',
            THANKS: '¡Gracias!',
            MONTH: 'Mes',
        },
        ERROR: {
            PATIENT_VIDEOCALL_DISCONNECTED:
                'Ha ocurrido un problema con la video llamada. La conexión se ha perdido. Intente conectarse nuevamente.',
            CHECK_CONNECTION:
                'Por favor chequee su conexión, espere un momento y vuelva a intentar.',
        },
        ALREADY_CONNECTED:
            'Ya existe un usuario conectado, por favor espere unos minutos e intente ingresar nuevamente.',
    },
};
