import { Component, OnInit, ChangeDetectorRef, Output, EventEmitter, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HistoryService } from '../../history.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-alergias',
  templateUrl: './alergias.component.html',
  styleUrls: ['./alergias.component.scss']
})

export class AlergiasComponent implements OnInit {
  // Variables publicas
  public data: any;

  @Input() paciente_id?: any;
  @Output('results') results = new EventEmitter;

  constructor(private _DomSanitizationService: DomSanitizer, private historyService: HistoryService, private route: ActivatedRoute, private router: Router, private cdf: ChangeDetectorRef) {
    this.data = {
      alergias: []
    }
  }
  ngOnInit() {
    this.obtenerAlergia(this.paciente_id);

    this.historyService.subscribe(res => {
      if (res === 'listar_alergias') {
        this.obtenerAlergia(this.paciente_id);
      }
    });
  }

  obtenerAlergia(paciente_id) {
    this.historyService.getAllergyPacient(paciente_id).subscribe(res => {
      this.data.alergias = [];
      res.forEach(element => {
        this.data.alergias.push({
          id: element.id,
          imagen: this._DomSanitizationService.bypassSecurityTrustUrl(element.tipo.imagen),
          descripcion: element.descripcion,
          desde: element.desde,
          hasta: element.hasta,
          tipo: element.tipo,
          publicador: element.publicador
        });
      });

      if(this.data.alergias.length > 0) this.results.emit(true);
      this.cdf.detectChanges();
    }, err => {
      // console.log(err);
    });
  }

  agregarNuevaAlergia() {
    const cita_id = this.route.snapshot.paramMap.get('cita_id');
    const consulta_id = this.route.snapshot.paramMap.get('consulta_id');
    const om_id = this.route.snapshot.paramMap.get('om_id');
    // this.router.navigate(['medic/history/' + paciente_id + '/' + cita_id + '/agregar_alergia']);
    this.router.navigate(['/medic/history/' + this.paciente_id + '/' + cita_id + '/agregar_alergia' + '/' + consulta_id + '/' + om_id])
  }

}