import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import {
    MatIconModule,
    MatRippleModule,
    MatMenuModule,
    MatButtonModule,
    MatDialogModule,
    MatTooltipModule,
} from '@angular/material';

import { TranslateModule } from '@ngx-translate/core';

import {
    FuseNavigationComponent,
    LogoutDialogComponent,
} from './navigation.component';
import { FuseNavVerticalItemComponent } from './vertical/item/item.component';
import { FuseNavVerticalCollapsableComponent } from './vertical/collapsable/collapsable.component';
import { FuseNavVerticalGroupComponent } from './vertical/group/group.component';
import { FuseNavHorizontalItemComponent } from './horizontal/item/item.component';
import { FuseNavHorizontalCollapsableComponent } from './horizontal/collapsable/collapsable.component';
import { FuseSharedModule } from '@fuse/shared.module';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FuseSharedModule,

        MatIconModule,
        MatRippleModule,
        MatDialogModule,
        MatButtonModule,
        MatTooltipModule,

        TranslateModule.forChild(),
    ],
    exports: [FuseNavigationComponent],
    declarations: [
        FuseNavigationComponent,
        FuseNavVerticalGroupComponent,
        FuseNavVerticalItemComponent,
        FuseNavVerticalCollapsableComponent,
        FuseNavHorizontalItemComponent,
        FuseNavHorizontalCollapsableComponent,
        LogoutDialogComponent,
    ],
    entryComponents: [LogoutDialogComponent],
})
export class FuseNavigationModule {}
