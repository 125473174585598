import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { HistoryService } from '../../../history.service';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import {
    MatSnackBarHorizontalPosition,
    MatSnackBarVerticalPosition,
    MatSnackBar,
    MAT_DIALOG_DATA,
    MatDialogRef,
} from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-traslado-modal',
    templateUrl: './traslado-modal.component.html',
    styleUrls: ['./traslado-modal.component.scss'],
})
export class TrasladoModalComponent implements OnInit {
    private horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    private verticalPosition: MatSnackBarVerticalPosition = 'top';

    currentView = 'area';
    trasladoForm: FormGroup;

    asignar = {
        area: { id: 0, descripcion: 'Sin asignar' },
        piso: { id: 0, descripcion: 'Sin asignar' },
        cuarto: { id: 0, descripcion: 'Sin asignar' },
        cama: { id: 0, nombre: 'Sin asignar' },
    };

    area = [];
    piso = [];
    cuarto = [];
    cama = [];
    search = [];

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private historyService: HistoryService,
        private fuseProgressBarService: FuseProgressBarService,
        private snackBar: MatSnackBar,
        private _translateService: TranslateService,
        public dialogRef: MatDialogRef<TrasladoModalComponent>
    ) {}

    ngOnInit(): void {
        this.trasladoForm = this.fb.group({
            traslado: [''],
        });

        this.asignar.area.descripcion = this.asignar.piso.descripcion = this.asignar.cuarto.descripcion = this.asignar.cama.nombre = this._translateService.instant(
            'MENU_IZQUIERDO.ASSIGN'
        );

        this.getAreaConsulta();
        this.getPiso();
    }

    bgOption(selectedView: string): Object {
        if (selectedView !== this.currentView) {
            return {
                backgroundColor: '#eeeeee',
            };
        } else {
            return {
                backgroundColor: '#dddddd',
                borderBottom: '2px solid #039be5',
                color: '#039be5',
            };
        }
    }

    selectOption(selected: string, ele: any): void {
        switch (selected) {
            case 'area':
                this.asignar.area = ele;
                this.changeView('piso');
                this.getCuarto();
                break;
            case 'piso':
                this.asignar.piso = ele;
                this.changeView('cuarto');
                this.getCuarto();
                break;
            case 'cuarto':
                this.asignar.cuarto = ele;
                this.changeView('cama');
                this.getCama();
                break;
            case 'cama':
                this.asignar.cama = ele;
                break;
        }
    }

    changeView(selected: string): void {
        if (selected !== this.currentView) {
            switch (selected) {
                case 'area':
                    this.search = this.area;
                    break;
                case 'piso':
                    this.search = this.piso;
                    break;
                case 'cuarto':
                    this.search = this.cuarto;
                    break;
                case 'cama':
                    this.search = this.cama;
                    break;
            }
            this.currentView = selected;
            this.trasladoForm.get('traslado').setValue('');
        }
    }

    doSearch(view = this.currentView): void {
        const value = this.trasladoForm.get('traslado').value.toLowerCase();
        this.search = [];

        let arr;
        switch (view) {
            case 'area':
                arr = this.area;
                break;
            case 'piso':
                arr = this.piso;
                break;
            case 'cuarto':
                arr = this.cuarto;
                break;
            case 'cama':
                arr = this.cama;
                break;
        }

        arr.forEach((element) => {
            if (element.descripcion.toLowerCase().indexOf(value) !== -1) {
                this.search.push(element);
            }
        });

        if (this.search.length === 0) {
            switch (view) {
                case 'area':
                    this.search = this.area;
                    break;
                case 'piso':
                    this.search = this.piso;
                    break;
                case 'cuarto':
                    this.search = this.cuarto;
                    break;
                case 'cama':
                    this.search = this.cama;
                    break;
            }
        }
    }

    postTraslado(): void {
        if (this.asignar.cama.id !== 0) {
            const body = {
                cita: this.data.cita_id,
                tipo_consulta: this.data.consulta_id,
                especialidad: this.data.especialidad,
                medico: this.data.medico,
                cama: this.asignar.cama.id,
            };
            this.fuseProgressBarService.show();
            this.historyService.postTraslado(body).subscribe(
                (res) => {
                    this.snackBar.open(res.message, 'INFO', {
                        horizontalPosition: this.horizontalPosition,
                        verticalPosition: this.verticalPosition,
                        duration: 3000,
                        panelClass: 'custom-blue',
                    });
                    this.dialogRef.close({
                        status: 200,
                    });
                    this.fuseProgressBarService.hide();
                },
                (err) => {
                    this.snackBar.open(err.message, 'ERROR', {
                        horizontalPosition: this.horizontalPosition,
                        verticalPosition: this.verticalPosition,
                        duration: 3000,
                        panelClass: 'custom-red',
                    });
                    this.fuseProgressBarService.hide();
                }
            );
        } else {
            this.snackBar.open('Seleccione todos los campos', 'OK', {
                horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,
                duration: 3000,
                panelClass: 'custom-red',
            });
            this.fuseProgressBarService.hide();
        }
    }

    getAreaConsulta(): void {
        this.fuseProgressBarService.show();
        this.historyService.getAreaConsulta(this.data.consulta_id).subscribe(
            (res) => {
                this.fuseProgressBarService.hide();
                this.area = [...res];
                this.search = [...res];
            },
            (err) => {
                this.fuseProgressBarService.hide();
                this.snackBar.open(err.message, 'ERROR', {
                    horizontalPosition: this.horizontalPosition,
                    verticalPosition: this.verticalPosition,
                    duration: 3000,
                    panelClass: 'custom-red',
                });
            }
        );
    }

    getPiso(): void {
        this.fuseProgressBarService.show();
        this.historyService.getPiso().subscribe(
            (res) => {
                this.piso = [...res];
                this.fuseProgressBarService.hide();
            },
            (err) => {
                this.snackBar.open(err.message, 'ERROR', {
                    horizontalPosition: this.horizontalPosition,
                    verticalPosition: this.verticalPosition,
                    duration: 3000,
                    panelClass: 'custom-red',
                });
                this.fuseProgressBarService.hide();
            }
        );
    }

    getCuarto(): void {
        if (
            Object.keys(this.asignar.area).length === 3 &&
            Object.keys(this.asignar.piso).length === 3
        ) {
            this.fuseProgressBarService.show();
            this.historyService
                .getCuarto(this.asignar.area.id, this.asignar.piso.id)
                .subscribe(
                    (res) => {
                        this.cuarto = [...res];
                        if (this.currentView === 'cuarto') {
                            this.search = [...res];
                        }
                        this.fuseProgressBarService.hide();
                    },
                    (err) => {
                        this.snackBar.open(err.message, 'ERROR', {
                            horizontalPosition: this.horizontalPosition,
                            verticalPosition: this.verticalPosition,
                            duration: 3000,
                            panelClass: 'custom-red',
                        });
                        this.fuseProgressBarService.hide();
                    }
                );
        }
    }

    getCama(): void {
        if (Object.keys(this.asignar.cuarto).length > 3) {
            this.fuseProgressBarService.show();
            this.historyService.getCama(this.asignar.cuarto.id).subscribe(
                (res) => {
                    this.cama = [...res];
                    if (this.currentView === 'cama') {
                        this.search = [...res];
                    }
                    this.fuseProgressBarService.hide();
                },
                (err) => {
                    this.snackBar.open(err.message, 'ERROR', {
                        horizontalPosition: this.horizontalPosition,
                        verticalPosition: this.verticalPosition,
                        duration: 3000,
                        panelClass: 'custom-red',
                    });
                    this.fuseProgressBarService.hide();
                }
            );
        }
    }
}
