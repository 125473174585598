export const locale = {
    lang: 'es',
    data: {
        MAIN: { LOADING_PLEASE_WAIT: 'Cargando, por favor espere' },
        DIALOG: {
            CONFIRM: 'Confirmar',
            ACCEPT: 'Aceptar',
            CANCEL: 'Cancelar',
            CLOSE: 'Cerrar',
            REPLACE: 'Reemplazar',
            NEXT: 'Siguiente',
            PRECONSULT: 'Pre-consulta',
            REGISTERED_BY: 'Registrado por',
            MODIFIED_BY: 'Modificado por',
            APPOINTMENT_SUCCESS_MSG: 'Su cita ha sido agendada',
            PAYMENT_SUCCESS_MSG: 'El pago fue realizado con éxito',
            PAYMENT_ZELLE_SUCCESS_MSG:
                'El pago fue registrado con éxito y se confirmara en los proximos dias',
            SUCCESS_FORM: 'Cuestionario actualizado',
        },
        MAIL_DIALOG: {
            CLOSE: 'Cerrar',
            EMAIL_TO: 'Correo',
            AND: 'y',
            X_OTHERS: 'otro(s)',
            INPUT_ADDR: 'Escriba direcciones de correo',
            SEND: 'Enviar',
            CANCEL: 'Cancelar',
            SEND_TO: 'Para',
            SUBJECT: 'Asunto',
            FROM: 'De',
            SELECT_SENDER: 'Seleccione un remitente',
            CARBON_COPY: 'Cc',
            BLIND_CARBON_COPY: 'Cco',
            ATTACH_INVOICE_PDF: 'Adjuntar factura en PDF',
            ATTACH_FILE_PDF: 'Adjuntar archivo PDF',
            ATTACH_FILES: 'Adjuntar archivos',
            ADD_FILE: 'Agregar archivo',
            REMOVE_FILE: 'Remover archivo',
            PREVIEW_FILE: 'Vista previa',
        },
        QUILL: {
            FORMAT_OPTIONS: 'Opciones de formato',
            LINK: 'Insertar enlace',
            IMAGE: 'Insertar imágen',
            FONT: 'Fuente',
            SIZE: 'Tamaño',
            SMALL: 'Pequeño',
            LARGE: 'Largo',
            BIG: 'Enorme',
            BOLD: 'Negrita',
            ITALIC: 'Cursiva',
            UNDERLINE: 'Subrayado',
            TEXT_COLOR: 'Color del texto',
            BACKGROUND_COLOR: 'Color del fondo',
            ALIGNMENT: 'Alineación',
            LIST_ORDERED_NUMBER: 'Lista ordenada de números',
            LIST_ORDERED_BULLET: 'Lista ordenada de viñetas',
            MORE_INDENT: 'Más sangría',
            DECREASE_INDENT: 'Reducir sangría',
            QUOTE: 'Cita',
            STRIKETHROUGH: 'Tachado',
            CLEAR: 'Eliminar formato',
            UNDO: 'Deshacer',
            REDO: 'Rehacer',
        },
        PRECONSULT: {
            TITLE: 'Pre-consulta',
        },
    },
};
