export const locale = {
    lang: 'en',
    data: {
        HISTORY: {
            MEDIC_HISTORY: 'Complete history',
            EVO_NOTE: 'Evolutive note',
        },
        MONTH: {
            JANUARY: 'January',
            FREBRUARY: 'February',
            MARCH: 'March',
            APRIL: 'April',
            MAY: 'May',
            JUNE: 'June',
            JULY: 'July',
            AUGUST: 'August',
            SEPTEMBER: 'September',
            OCTOBER: 'October',
            NOVEMBER: 'November',
            DECEMBER: 'December',
        },
    },
};
