import { NgModule } from '@angular/core';
import { MatButtonModule, MatTooltipModule } from '@angular/material';
import { RouterModule } from '@angular/router';

import { FuseSidebarModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';

import { ContentModule } from 'app/layout/components/content/content.module';
import { FooterModule } from 'app/layout/components/footer/footer.module';
import { NavbarModule } from 'app/layout/components/navbar/navbar.module';
import { QuickPanelModule } from 'app/layout/components/quick-panel/quick-panel.module';
import { ToolbarModule } from 'app/layout/components/toolbar/toolbar.module';

import { VerticalLayout1Component } from 'app/layout/vertical/layout-1/layout-1.component';
import { ActiveVideocallDialogModule } from 'app/modules/active-videocall-dialog/active-videocall-dialog.module';
import { ToolbarMenuModule } from 'app/navigation/toolbar-menu/toolbar-menu.module';
import { PipesCustomModule } from 'app/pipes/pipes.module';

@NgModule({
    declarations: [VerticalLayout1Component],
    imports: [
        RouterModule,
        TranslateModule,

        FuseSharedModule,
        FuseSidebarModule,
        ActiveVideocallDialogModule,
        ToolbarMenuModule,

        // Material
        MatTooltipModule,
        MatButtonModule,

        ContentModule,
        FooterModule,
        NavbarModule,
        QuickPanelModule,
        ToolbarModule,

        PipesCustomModule,
    ],
    exports: [VerticalLayout1Component],
})
export class VerticalLayout1Module {}
