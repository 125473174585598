import {
    Component,
    OnInit,
    ChangeDetectorRef,
    ViewChild,
    EventEmitter,
    Output,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HistoryService } from '../../history.service';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { ITreeOptions } from 'angular-tree-component';
import { CitasService } from '../../citas/citas.service';
import { MatDialog } from '@angular/material';
import { CitasModalComponent } from 'app/main/portal/pacientes/citas-modal/citas-modal.component';
import { element } from 'protractor';
import { toInteger } from 'lodash';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from './i18n/en';
import { locale as spanish } from './i18n/es';

@Component({
    selector: 'app-historial-citas',
    templateUrl: './historial_citas.component.html',
    styleUrls: ['./historial_citas.component.scss'],
})
export class HistorialCitasComponent implements OnInit {
    // Variables publicas
    data: any;
    portal: boolean;
    node = [];
    filter = '';
    options: ITreeOptions = {
        // displayField: 'nodeName',
        isExpandedField: 'expanded',
        // idField: 'uuid',
        // hasChildrenField: 'nodes',
        // nodeHeight: 23,
        // allowDragoverStyling: true,
        // levelPadding: 10,
        // useVirtualScroll: true,
        animateExpand: true,
        // scrollOnActivate: true,
        animateSpeed: 15,
        // animateAcceleration: 1.2,
        // scrollContainer: document.documentElement, // HTML
    };
    view: any;
    paciente_id: any;

    @ViewChild('tree', { static: true }) tree;
    @Output() historicoCita = new EventEmitter();
    @Output() selectedHistory = new EventEmitter();

    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private historyService: HistoryService,
        private route: ActivatedRoute,
        private router: Router,
        private citasServ: CitasService,
        private translate: TranslateService
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, spanish);
        this.data = {
            historial: [],
        };
    }

    ngOnInit(): void {
        this.router.url.includes('portal')
            ? (this.portal = true)
            : (this.portal = false);

        this.paciente_id = this.route.snapshot.paramMap.get('paciente_id');
        this.view = this.route.snapshot.paramMap.get('view_id');
        this.obtenerHistoricoCitas(this.paciente_id);
    }

    obtenerHistoricoCitas(paciente_id): void {
        this.data.historial = [];
        this.historyService.getHistoricoCitas(paciente_id).subscribe(
            (res) => {
                this.data.historial = [...res];
                // this.prepareTree(res);
                this.year(res);
                this.month(res);
                this.day(res);
                this.tree.treeModel.update();
            },
            (err) => {
                console.error(err);
            }
        );
    }

    // Evento que ocurre al hacer click en uno de los nodos
    onEvent($event): void {
        const nodeParams = $event.node.id.split('/');
        const params = window.location.hash.split('/');

        if (
            $event.node.id.length > 10 &&
            this.view !== 'citas' &&
            !this.portal
        ) {
            window.open(
                `${params[0]}/${params[1]}/${params[2]}/${params[3]}/${nodeParams[0]}/citas/${nodeParams[1]}/${nodeParams[0]}`,
                '_blank'
            );
        } else if ($event.node.id.length > 10 && !this.portal) {
            this.router.navigate([
                `${params[1]}/${params[2]}/${params[3]}/${nodeParams[0]}/citas/${nodeParams[1]}/${nodeParams[0]}`,
            ]);
            this.historicoCita.emit({
                cita_id: nodeParams[0],
                consulta_id: nodeParams[1],
            });
            this.citasServ.changeId(nodeParams[0]);
        } else if ($event.node.id.length > 10) {
            this.selectedHistory.emit({
                paciente_id: this.paciente_id,
                cita_id: nodeParams[0],
                consulta_id: nodeParams[1],
            });
        }
    }

    filterNodes(txt = this.filter): void {
        this.tree.treeModel.filterNodes((node) => {
            return node.data.name.toLowerCase().includes(txt.toLowerCase());
        });

        const hidden = Object.keys(this.tree.treeModel.hiddenNodeIds);

        hidden.forEach((ele) => {
            const node = this.tree.treeModel.getNodeById(ele);

            switch (node.level) {
                case 1:
                    break;

                case 2:
                    if (
                        node.parent.data.name
                            .toLowerCase()
                            .includes(txt.toLowerCase())
                    ) {
                        node.show();
                    }
                    break;

                case 3:
                    if (
                        node.parent.data.name
                            .toLowerCase()
                            .includes(txt.toLowerCase())
                    ) {
                        node.show();
                    } else {
                        const otherNode = this.tree.treeModel.getNodeById(
                            node.parent.data.id
                        );

                        if (
                            otherNode.parent.data.name
                                .toLowerCase()
                                .includes(txt.toLowerCase())
                        ) {
                            node.show();
                        }
                    }
                    break;
            }
        });
    }

    // Mapeo de años
    year(response: any[]): void {
        response.forEach((ele, i) => {
            const date = ele.fecha.split('-');
            if (i === 0) {
                this.node.push({
                    id: date[0],
                    name: date[0],
                    children: [],
                });
            } else if (this.node[this.node.length - 1].id !== date[0]) {
                this.node.push({
                    id: date[0],
                    name: date[0],
                    children: [],
                });
            }
        });
    }

    // Mapeo de meses por años
    month(response: any[]): void {
        response.forEach((ele) => {
            const date = ele.fecha.split('-');
            const index = (nodeElement) => nodeElement.id === date[0];

            if (this.node[this.node.findIndex(index)].children.length === 0) {
                this.node[this.node.findIndex(index)].children.push({
                    id: date[1],
                    name: this.getMonthName(toInteger(date[1])),
                    children: [],
                });
            } else if (
                this.node[this.node.findIndex(index)].children[
                    this.node[this.node.findIndex(index)].children.length - 1
                ].id !== date[1]
            ) {
                this.node[this.node.findIndex(index)].children.push({
                    id: date[1],
                    name: this.getMonthName(toInteger(date[1])),
                    children: [],
                });
            }
        });
    }

    // Mapeo de dia por meses
    day(response: any[]): void {
        response.forEach((ele) => {
            const date = ele.fecha.split('-');
            const indexYear = (nodeElement) => nodeElement.id === date[0];
            const indexMonth = (nodeElement) => nodeElement.id === date[1];

            const iY = this.node.findIndex(indexYear);
            const year = this.node[iY];

            this.node[iY].children[
                year.children.findIndex(indexMonth)
            ].children.push({
                id: ele.id + '/' + ele.tipo_consulta.id,
                name: `${date[2].substring(0, 2)} - ${
                    ele.especialidad.nombre_corto
                } - ${ele.medico.nombre} - ${
                    ele.plantilla_cita !== null
                        ? ele.plantilla_cita.nombre
                        : ele.nota_evolutiva
                        ? this.translate.instant('HISTORY.EVO_NOTE')
                        : this.translate.instant('HISTORY.MEDIC_HISTORY')
                } `,
                tipo_consulta: ele.tipo_consulta.id,
                tipo_atencion: ele.tipo_atencion,
            });
        });
    }

    // Retorna string con el nombre de mes
    getMonthName(num: number): string {
        switch (num) {
            case 1:
                return this.translate.instant('MONTH.JANUARY');
            case 2:
                return this.translate.instant('MONTH.FREBRUARY');
            case 3:
                return this.translate.instant('MONTH.MARCH');
            case 4:
                return this.translate.instant('MONTH.APRIL');
            case 5:
                return this.translate.instant('MONTH.MAY');
            case 6:
                return this.translate.instant('MONTH.JUNE');
            case 7:
                return this.translate.instant('MONTH.JULY');
            case 8:
                return this.translate.instant('MONTH.AUGUST');
            case 9:
                return this.translate.instant('MONTH.SEPTEMBER');
            case 10:
                return this.translate.instant('MONTH.OCTOBER');
            case 11:
                return this.translate.instant('MONTH.NOVEMBER');
            case 12:
                return this.translate.instant('MONTH.DECEMBER');
        }
    }

    onUpdateData(tree): void {
        if (window.location.href.includes('portal')) {
            tree.treeModel.expandAll();
        }
    }
}
