import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

import { navigation } from 'app/navigation/navigation';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from '@fuse/components/navigation/i18n/en';
import { locale as spanish } from '@fuse/components/navigation/i18n/es';
import { MatDialog } from '@angular/material';
import { LogoutDialogComponent } from '@fuse/components/navigation/navigation.component';
import * as moment from 'moment';
import { PortalService } from 'app/main/portal/portal.service';
import { ConfirmationDialogComponent } from 'app/modules/shared/components/confirmation-dialog/confirmation-dialog.component';

@Component({
    selector: 'toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ToolbarComponent implements OnInit, OnDestroy {
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    languages: any;
    navigation: any;
    selectedLanguage: any;
    userStatusOptions: any[];
    fuseConfig: any;
    data: any;
    app_route = '';
    toggleOnline = false;

    // selected: any;
    // ruta: any;
    // tipo_consulta: string = '';

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {TranslateService} _translateService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _fuseTranslation: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private dom: DomSanitizer,
        private router: Router,
        private dialog: MatDialog,
        private portalService: PortalService
    ) {
        this._fuseTranslation.loadTranslations(english, spanish);
        // Set the defaults
        this.userStatusOptions = [
            {
                title: 'Online',
                icon: 'icon-checkbox-marked-circle',
                color: '#4CAF50',
            },
            {
                title: 'Away',
                icon: 'icon-clock',
                color: '#FFC107',
            },
            {
                title: 'Do not Disturb',
                icon: 'icon-minus-circle',
                color: '#F44336',
            },
            {
                title: 'Invisible',
                icon: 'icon-checkbox-blank-circle-outline',
                color: '#BDBDBD',
            },
            {
                title: 'Offline',
                icon: 'icon-checkbox-blank-circle-outline',
                color: '#616161',
            },
        ];

        this.languages = [
            {
                id: 'en',
                title: 'English',
                flag: 'us',
            },
            {
                id: 'es',
                title: 'Spanish',
                flag: 'es',
            },
        ];

        this.navigation = navigation;
        this.data = {
            image: 'assets/images/avatars/profile.jpg',
        };

        // Set the private defaults
        this._unsubscribeAll = new Subject();

        this.router.events.forEach((event) => {
            // Esto sucede cuando cambiamos de History a otra ruta cualquiera
            if (event instanceof NavigationEnd) {
                this.ngOnInit();
            }
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this._fuseTranslation.loadTranslations(english, spanish);
        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((settings) => {
                this.fuseConfig = settings;
                this.data.image = this.dom.bypassSecurityTrustUrl(
                    settings.user.image
                );
                this.horizontalNavbar =
                    settings.layout.navbar.position === 'top';
                this.rightNavbar = settings.layout.navbar.position === 'right';
                this.hiddenNavbar = settings.layout.navbar.hidden === true;
            });

        // Set the selected language from default languages
        this.selectedLanguage = _.find(this.languages, {
            id: this._translateService.currentLang,
        });

        const params = window.location.hash.split('/');
        this.menuOptionSelected(params);
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    /**
     * Search
     *
     * @param value
     */
    search(value): void {
        // Do your search here...
        // console.log(value);
    }

    /**
     * Set the language
     *
     * @param lang
     */
    setLanguage(lang): void {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;

        // Use the selected language for translations
        this._translateService.use(lang.id);

        // Set selected language on localStorage
        localStorage.setItem('language', lang.id);
    }

    menuOptionSelected(urlParams: string[]): void {
        switch (urlParams[1]) {
            case 'medic':
            case 'portal':
                this.app_route = 'portal';
                break;

            case 'pacientes':
                this.app_route = 'pacientes';
                break;

            case 'secretary':
                this.app_route = 'secretary';
                break;

            case 'admin':
                this.app_route = 'admin';
                break;
        }
    }

    logout() {
        if (this.fuseConfig.isMedic) {
            this.getListaEspera();
        } else {
            const dialog = this.dialog.open(LogoutDialogComponent, {
                panelClass: 'custom-dialog',
                autoFocus: false,
                data: {},
            });
        }
    }

    getListaEspera(): void {
        this.portalService
            .getListaEspera(
                this.fuseConfig.user.id,
                this.fuseConfig.user.ambulatorio[0].ambulatorio,
                moment().format('YYYY-MM-DD'),
                moment().format('YYYY-MM-DD')
            )
            .subscribe(
                async (res) => {
                    const filtered = res.filter((ele) => ele.cita === null);
                    if (filtered.length === 0 || this.toggleOnline === false) {
                        const dialog = this.dialog.open(LogoutDialogComponent, {
                            panelClass: 'custom-dialog',
                            autoFocus: false,
                            data: {},
                        });
                    } else {
                        const dialogRef = this.dialog.open(
                            ConfirmationDialogComponent,
                            {
                                width: '280px',
                                panelClass: 'custom-dialog',
                                autoFocus: false,
                                data: {
                                    text: this._translateService.instant(
                                        'WAITING_LIST.CONFIRM_DISCONNECT_MSG'
                                    ),
                                },
                            }
                        );
                        const sub = dialogRef
                            .afterClosed()
                            .subscribe(async (res) => {
                                if (res) {
                                    const dialog = this.dialog.open(
                                        LogoutDialogComponent,
                                        {
                                            width: '280px',
                                            panelClass: 'custom-dialog',
                                            autoFocus: false,
                                            data: {},
                                        }
                                    );
                                }
                                sub.unsubscribe();
                            });
                    }
                },
                (err) => {}
            );
    }

    // logout(): void {
    //     this.dialog.open(LogoutDialogComponent, {
    //         panelClass: 'custom-dialog',
    //     });
    // }

    profile(): void {
        this.router.navigate([`${this.app_route}/profile`]);
    }
}
