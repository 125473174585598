import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id: 'sales',
        title: 'Ventas',
        translate: 'NAV.SALES_TITLE',
        type: 'group',
        children: [
            {
                id: 'clients',
                title: 'Clients',
                translate: 'NAV.SALES.CLIENTS',
                type: 'item',
                icon: 'face',
                url: '/sales/customers',
            },
            {
                id: 'inventory',
                title: 'Inventory',
                translate: 'NAV.SALES.INVENTORY',
                type: 'item',
                icon: 'widgets',
                url: '/sales/inventory',
            },
            {
                id: 'tax',
                title: 'Tax',
                translate: 'NAV.SALES.TAX',
                type: 'item',
                icon: 'payment',
                url: '/sales/tax',
            },
            {
                id: 'unit',
                title: 'Unidad',
                translate: 'NAV.SALES.UNIT',
                type: 'item',
                icon: 'filter_1',
                url: '/sales/unit',
            },
            {
                id: 'invoices',
                title: 'Facturas',
                translate: 'Facturas',
                type: 'item',
                icon: 'library_books',
                url: '/sales/invoice',
            },
        ],
    },
];
