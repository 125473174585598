export const locale = {
    lang: 'es',
    data: {
        HISTORY: {
            MEDIC_HISTORY: 'Historia completa',
            EVO_NOTE: 'Nota evolutiva',
        },
        MONTH: {
            JANUARY: 'Enero',
            FREBRUARY: 'Febrero',
            MARCH: 'Marzo',
            APRIL: 'Abril',
            MAY: 'Mayo',
            JUNE: 'Junio',
            JULY: 'Julio',
            AUGUST: 'Agosto',
            SEPTEMBER: 'Septiembre',
            OCTOBER: 'Octubre',
            NOVEMBER: 'Noviembre',
            DECEMBER: 'Diciembre',
        },
    },
};
