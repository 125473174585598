import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse,
    HttpErrorResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { tap, map } from 'rxjs/internal/operators';
import { MatSnackBar } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class SessionInterceptor implements HttpInterceptor {
    constructor(
        private router: Router,
        private translate: TranslateService,
        private snackBar: MatSnackBar
    ) {}
    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            tap(
                (event: HttpEvent<any>) => {
                    if (event instanceof HttpResponse) {
                    }
                },
                (err: any) => {
                    if (err instanceof HttpErrorResponse) {
                        if (err.status === 0) {
                            this.snackBar.open(
                                this.translate.instant(
                                    'ERROR.CHECK_CONNECTION'
                                ),
                                '',
                                {
                                    verticalPosition: 'top',
                                    duration: 5000,
                                    panelClass: 'custom-red',
                                }
                            );
                        } else if (
                            this.router.url.includes('/consulta?room') &&
                            err.status !== 404
                        ) {
                            this.snackBar.open(
                                this.translate.instant(
                                    'ERROR.PATIENT_VIDEOCALL_DISCONNECTED'
                                ) +
                                    ` (${err.status}${
                                        err.error.code
                                            ? ` - ${err.error.code}`
                                            : err.error.message
                                            ? ` - ${err.error.message.code}`
                                            : ''
                                    })`,
                                '',
                                {
                                    verticalPosition: 'top',
                                    duration: 5000,
                                    panelClass: 'custom-red',
                                }
                            );
                            this.router.navigate(['/']);
                        } else if (
                            err.status === 403 &&
                            (err.error.message.code === 'S0001' ||
                                err.error.code === 'S0001' ||
                                err.error.message.code === 'S0002' ||
                                err.error.code === 'S0002' ||
                                err.error.message.code === 'S9999' ||
                                err.error.code === 'S9999')
                        ) {
                            this.router.navigate(['/']);
                        } else if (
                            err.status === 409 &&
                            (err.error.code === 'S0002' ||
                                err.error.message.code === 'S0002')
                        ) {
                            this.router.navigate(['']);
                        } else if (
                            err.status === 500 &&
                            (err.error.code === 'S9999' ||
                                err.error.message.code === 'S9999')
                        ) {
                            this.router.navigate(['/']);
                        } else if (err.status === 0) {
                            this.router.navigate(['/']);
                        } else if (
                            err.status === 429 &&
                            err.error.code === 'A0001'
                        ) {
                            this.router.navigate(['/']);
                        }
                    }
                }
            )
        );
    }
}
