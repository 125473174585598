import {
    Component,
    OnInit,
    ViewChild,
    ChangeDetectorRef,
    EventEmitter,
    Output,
    OnChanges,
    SimpleChanges,
    Input,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HistoryService } from '../../history.service';
import { BaseChartDirective } from 'ng2-charts';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import { MatTableDataSource } from '@angular/material';

@Component({
    selector: 'app-signos_vitales',
    templateUrl: './signos_vitales.component.html',
    styleUrls: ['./signos_vitales.component.scss'],
})
export class SignosVitalesComponent implements OnInit, OnChanges {
    @ViewChild(BaseChartDirective, { static: false }) chart: BaseChartDirective;
    form: FormGroup;
    signoSeleccionado = '';
    signoDescripcion = '';
    signoSeleccionadoAux = '';
    view_id = '';
    displayedColumns: string[] = ['sign', 'value'];
    dataSource = new MatTableDataSource<any>([]);
    toggleTable = false;

    // Variables publicas
    public data: any;
    public widget: any;
    public lengthLabel: any = 0;
    public infoLabel: any = '';

    @Output('results') results = new EventEmitter();
    @Input() paciente_id: string;

    constructor(
        private ref: ChangeDetectorRef,
        private historyService: HistoryService,
        private _formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router
    ) {
        this.data = {
            signos_vitales: [],
        };

        this.widget = {
            chartType: 'line',
            datasets: {},
            colors: [
                {
                    borderColor: '#3949ab',
                    backgroundColor: '#3949ab',
                    pointBackgroundColor: '#3949ab',
                    pointHoverBackgroundColor: '#3949ab',
                    pointBorderColor: '#ffffff',
                    pointHoverBorderColor: '#ffffff',
                },
                {
                    borderColor: 'rgba(30, 136, 229, 0.87)',
                    backgroundColor: 'rgba(30, 136, 229, 0.87)',
                    pointBackgroundColor: 'rgba(30, 136, 229, 0.87)',
                    pointHoverBackgroundColor: 'rgba(30, 136, 229, 0.87)',
                    pointBorderColor: '#ffffff',
                    pointHoverBorderColor: '#ffffff',
                },
            ],
            options: {
                spanGaps: false,
                legend: {
                    display: false,
                },
                maintainAspectRatio: false,
                tooltips: {
                    enabled: true,
                    position: 'nearest',
                    mode: 'index',
                    intersect: false,
                    // backgroundColor: 'rgba(255,255,255,0.9)',
                    // bodyFontColor: '#999',
                    // borderColor: '#999',
                    // borderWidth: 1,
                    // caretPadding: 15,
                    // colorBody: '#666',
                    displayColors: false,
                    // titleFontColor: '#999',
                    // titleMarginBottom: 10,
                    // xPadding: 15,
                    // yPadding: 15,
                    callbacks: {
                        label: (tooltipItem, data) => {
                            if (this.infoLabel == '') {
                                this.infoLabel =
                                    this.infoLabel +
                                    data.datasets[tooltipItem.datasetIndex]
                                        .data[tooltipItem.index] +
                                    '/';
                            } else {
                                this.infoLabel =
                                    this.infoLabel +
                                    data.datasets[tooltipItem.datasetIndex]
                                        .data[tooltipItem.index] +
                                    ' ' +
                                    data.datasets[tooltipItem.datasetIndex]
                                        .label;
                            }
                            if (data.datasets.length > 1) {
                                this.lengthLabel++;
                                if (this.lengthLabel >= 2) {
                                    let valor = this.infoLabel;
                                    this.lengthLabel = 0;
                                    this.infoLabel = '';
                                    return valor;
                                } else {
                                    return '';
                                }
                            } else {
                                return (
                                    data.datasets[tooltipItem.datasetIndex]
                                        .data[tooltipItem.index] +
                                    ' ' +
                                    data.datasets[tooltipItem.datasetIndex]
                                        .label
                                );
                            }
                        },
                    },
                },
                layout: {
                    padding: {
                        left: 0,
                        right: 0,
                    },
                },
                elements: {
                    point: {
                        radius: 4,
                        borderWidth: 2,
                        hoverRadius: 4,
                        hoverBorderWidth: 2,
                    },
                },
                scales: {
                    xAxes: [
                        {
                            gridLines: {
                                display: true,
                            },
                            ticks: {
                                fontColor: 'rgba(0,0,0,0.54)',
                            },
                        },
                    ],
                    yAxes: [
                        {
                            display: true,
                            gridLines: {
                                tickMarkLength: 16,
                            },
                            ticks: {
                                max: 100,
                            },
                        },
                    ],
                },
                plugins: {
                    filler: {
                        propagate: false,
                    },
                },
            },
        };
    }
    ngOnInit(): void {
        this.view_id = this.route.snapshot.paramMap.get('view_id');
        this.historyService.subscribe((res) => {
            if (res === 'listar_signos_vitales') {
                this.obtenerSignosVitales(this.paciente_id);
            }
        });
        this.form = this._formBuilder.group({
            selected: ['', Validators.nullValidator],
        });
    }

    // Cuando hay un cambio en url lo detecta
    ngOnChanges(changes: SimpleChanges): void {
        if (
            changes.paciente_id.currentValue !==
            changes.paciente_id.previousValue
        ) {
            this.obtenerSignosVitales(this.paciente_id);
        }
    }

    obtenerSignosVitales(paciente_id) {
        this.historyService.getVitalSigns(paciente_id).subscribe(
            (res) => {
                this.data.signos_vitales = [];
                res.forEach((element) => {
                    this.data.signos_vitales.push({
                        id: element.signo_vital.id,
                        descripcion: element.signo_vital.descripcion,
                        detalleValores: element.signo_vital.detalleValores,
                        imagen:
                            'data:image/jpeg;base64,' +
                            element.signo_vital.imagen,
                        cntValores: element.signo_vital.cntValores,
                        medida: element.signo_vital.medida,
                        tomas: element.tomas,
                    });
                });

                if (this.data.signos_vitales.length > 0)
                    this.results.emit(true);
                // this.changeGraph();
                this.dataSource.data = this.data.signos_vitales;
            },
            (err) => {
                // console.log(err);
            }
        );
    }

    cambiarOpcion(descripcion) {
        var replace = descripcion.replace(/ /g, '_').toLowerCase();

        this.signoSeleccionado = replace;
        this.signoDescripcion = descripcion;
        this.signoSeleccionadoAux = replace;

        // this.widget.options = {};
        this.chart.chart.config.data.labels =
            this.widget.datasets[this.signoSeleccionado][0].labels;
        this.chart.chart.config.options.scales.yAxes[0].ticks.max =
            this.widget.datasets[this.signoSeleccionado][0].max;
        this.chart.chart.config.data.datasets =
            this.widget.datasets[this.signoSeleccionado];
        this.chart.chart.update();
    }

    // changeGraph() {
    //     if (this.data.signos_vitales.length > 0) {
    //         var arrayDefault = this.data.signos_vitales[0]; //Valor por defecto al mostrar

    //         this.signoDescripcion = this.data.signos_vitales[0].descripcion;
    //         this.signoSeleccionado = arrayDefault.descripcion
    //             .replace(/ /g, '_')
    //             .toLowerCase();
    //         this.form.get('selected').setValue(arrayDefault);

    //         this.data.signos_vitales.forEach((element) => {
    //             var property = element.descripcion
    //                 .replace(/ /g, '_')
    //                 .toLowerCase(); // Se obtiene la propiedad en minuscula con pisos

    //             // Si es 1 solo se grafica un valor
    //             if (element.cntValores === 1) {
    //                 var tomas = [];
    //                 var fechas = [];
    //                 for (var i = element.tomas.length - 1; i >= 0; i--) {
    //                     tomas.push(element.tomas[i].valor1);
    //                     fechas.push(
    //                         moment(element.tomas[i].fecha).format('DD/MM')
    //                     );
    //                 }

    //                 Object.defineProperty(this.widget.datasets, property, {
    //                     value: [
    //                         {
    //                             label: element.medida,
    //                             data: tomas,
    //                             fill: 'start',
    //                             labels: fechas,
    //                             max: element.detalleValores[0].rangoGrafico
    //                                 .maximo,
    //                             type: 'line',
    //                             borderColor: '#3949ab',
    //                             backgroundColor: '#3949ab',
    //                             pointBackgroundColor: '#3949ab',
    //                             pointHoverBackgroundColor: '#3949ab',
    //                             pointBorderColor: '#ffffff',
    //                             pointHoverBorderColor: '#ffffff',
    //                         },
    //                     ],
    //                 });

    //                 // Si es 2 se grafica dos valores
    //             } else if (element.cntValores === 2) {
    //                 var tomas = [];
    //                 var tomas2 = [];
    //                 var fechas = [];
    //                 for (var i = element.tomas.length - 1; i >= 0; i--) {
    //                     tomas.push(element.tomas[i].valor1);
    //                     tomas2.push(element.tomas[i].valor2);
    //                     fechas.push(
    //                         moment(element.tomas[i].fecha).format('DD/MM')
    //                     );
    //                 }
    //                 Object.defineProperty(this.widget.datasets, property, {
    //                     value: [
    //                         {
    //                             label: element.medida,
    //                             data: tomas2,
    //                             labels: fechas,
    //                             fill: 'start',
    //                             max: element.detalleValores[0].rangoGrafico
    //                                 .maximo,
    //                             borderColor: '#3949ab',
    //                             backgroundColor: '#3949ab',
    //                             pointBackgroundColor: '#3949ab',
    //                             pointHoverBackgroundColor: '#3949ab',
    //                             pointBorderColor: '#ffffff',
    //                             pointHoverBorderColor: '#ffffff',
    //                         },
    //                         {
    //                             label: element.medida,
    //                             data: tomas,
    //                             labels: fechas,
    //                             fill: 'start',
    //                             max: element.detalleValores[0].rangoGrafico
    //                                 .maximo,
    //                             borderColor: 'rgba(30, 136, 229, 0.87)',
    //                             backgroundColor: 'rgba(30, 136, 229, 0.87)',
    //                             pointBackgroundColor:
    //                                 'rgba(30, 136, 229, 0.87)',
    //                             pointHoverBackgroundColor:
    //                                 'rgba(30, 136, 229, 0.87)',
    //                             pointBorderColor: '#ffffff',
    //                             pointHoverBorderColor: '#ffffff',
    //                         },
    //                     ],
    //                 });
    //             }
    //         });
    //         this.ref.detectChanges();
    //         if (this.signoSeleccionadoAux) {
    //             this.cambiarOpcion(this.signoSeleccionadoAux);
    //         } else {
    //             this.cambiarOpcion(this.signoSeleccionado);
    //         }
    //     }
    // }

    agregarNuevoSignovital() {
        const paciente_id = this.route.snapshot.paramMap.get('paciente_id');
        const cita_id = this.route.snapshot.paramMap.get('cita_id');
        const consulta_id = this.route.snapshot.paramMap.get('consulta_id');
        const om_id = this.route.snapshot.paramMap.get('om_id');
        this.router.navigate([
            '/medic/history/' +
                paciente_id +
                '/' +
                cita_id +
                '/agregar_signos_vitales' +
                '/' +
                consulta_id +
                '/' +
                om_id,
        ]);
    }

    returnDate(fecha: string, hora: string): string {
        return moment
            .utc(fecha + 'T' + hora + 'Z')
            .format('DD/MM/YYYY - hh:mm A');
    }

    outofRange(element: any): boolean {
        if (element.cntValores === 1) {
            if (
                element.tomas[0].valor1 >=
                    element.detalleValores[0].rangoNormal.minimo &&
                element.tomas[0].valor1 <=
                    element.detalleValores[0].rangoNormal.maximo
            ) {
                return false;
            }
        } else {
            if (
                element.tomas[0].valor1 >=
                    element.detalleValores[0].rangoNormal.minimo &&
                element.tomas[0].valor1 <=
                    element.detalleValores[0].rangoNormal.maximo &&
                element.tomas[0].valor2 >=
                    element.detalleValores[1].rangoNormal.minimo &&
                element.tomas[0].valor2 <=
                    element.detalleValores[1].rangoNormal.maximo
            )
                return false;
        }
        return true;
    }

    getImage(val) {
        switch (val) {
            case '5bd20866-3478-53f7-bc79-d2aae304b443':
                return 'assets/images/signos-vitales/oximetria_color.png';
            case 'ff6c5a08-b2fa-56b6-ac00-6830f4074b6a':
                return 'assets/images/signos-vitales/tension_arterial_color.png';
            case '334b6b31-12a2-5bfc-bf4f-870c0954b343':
                return 'assets/images/signos-vitales/talla_color.png';
            case 'afca48e9-59ea-5a28-b8a4-88e0cc6eda1c':
                return 'assets/images/signos-vitales/frecuencia_cardiaca_color.png';
            case '8611ccd6-fa12-11de-ab16-001a64a3e00a':
                return 'assets/images/signos-vitales/sato2_color.png';
            case 'e3818706-97a7-11e0-bc2e-00215edb8b08':
                return 'assets/images/signos-vitales/presion_arterial_media_color.png';
            case '6f2acb30-fa12-11de-8e55-001a64a3e00a':
                return 'assets/images/signos-vitales/presion_venosa_central_color.png';
            case '1026a71e-97a8-11e0-a284-00215edb8b08':
                return 'assets/images/signos-vitales/presion_intracraneal_color.png';
            case 'c6577abe-8c0f-5a02-ac84-aa94c2247b1e':
                return 'assets/images/signos-vitales/frecuencia_respiratoria_color.png';
            case '23de214c-97a8-11e0-9c1d-00215edb8b08':
                return 'assets/images/signos-vitales/presion_arterial_pulmonar_color.png';
            case 'b80c8fef-a677-5340-85fb-2c162d75df03':
                return 'assets/images/signos-vitales/peso_color.png';
            case 'c093c7f6-6edf-595e-9539-70de788efbaa':
                return 'assets/images/signos-vitales/temperatura_color.png';
            case 'e287dd64-e392-11e0-a559-00215edb8b08':
                return 'assets/images/signos-vitales/glicemia_capilar_insulina_color.png';
            case 'fa80d442-f1e4-11e0-ae4c-00215edb8b08':
                return 'assets/images/signos-vitales/evacuaciones_color.png';
            case '20ae4faa-f1e5-11e0-8d10-00215edb8b08':
                return 'assets/images/signos-vitales/vomito_color.png';
            case 'e1e5b788-f429-5a32-9f7e-63865921a5e5':
                return 'assets/images/signos-vitales/masa_muscular.png';
            case '143e3022-c580-11eb-a051-0050562d2f03':
                return 'assets/images/signos-vitales/grado.png';
        }
    }
}
