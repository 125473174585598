import { ThrowStmt } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class BotoneraAccionesMenuService {
    private tipoCitaObservable = new BehaviorSubject(false);
    tipoCita = this.tipoCitaObservable.asObservable();

    private templateObservable = new BehaviorSubject(false);
    template = this.templateObservable.asObservable();

    constructor() {}

    /**
     * Utilizado para actualizar la variable
     */
    updateVariable(param: any): void {
        this.tipoCitaObservable.next(param);
    }

    /**
     * Utilizado para actualizar el template
     */
    updateTemplate(param: any): void {
        this.templateObservable.next(param);
    }
}
