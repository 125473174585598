export const locale = {
    lang: 'es',
    data: {
        FOOTER: {
            TRIAL_PERIOD_1: 'Faltan',
            TRIAL_PERIOD_2: 'para que el periodo de prueba expire',
            DAYS: 'días',
        },
    },
};
