import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

import { FuseSharedModule } from '@fuse/shared.module';

import { PipesCustomModule } from 'app/pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { FilePreviewDialogComponent } from './file-preview-dialog.component';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';

@NgModule({
    declarations: [
        FilePreviewDialogComponent,
    ],
    imports: [
        // RouterModule.forChild(routes),
        TranslateModule,

        MatButtonModule,
        MatCardModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatRadioModule,
        MatSidenavModule,
        MatToolbarModule,
        MatTooltipModule,

        FuseSharedModule,
        PipesCustomModule,
        PdfJsViewerModule,
    ],
    exports: [FilePreviewDialogComponent],
    entryComponents: [
        FilePreviewDialogComponent
    ]
})
export class FilePreviewDialogModule {}
