export const locale = {
    lang: 'en',
    data: {
        NAV: {
            SALES_TITLE: 'Sales',
            PROFILE: {
                TITLE: 'Profile',
                BADGE: '25',
            },
            HOME: {
                TITLE: 'Home',
                BADGE: '25',
            },
            MEDIC_MENU: 'Medic',
            ADMIN_MENU: 'Admin',
            PATIENTS_OF_THE_DAY: {
                TITLE: 'Patients of the Day',
            },
            PRESUPUESTO: {
                TITLE: 'Budget',
            },
            HONORARIOS: {
                TITLE: 'Fee',
            },
            AGENDA: {
                TITLE: 'Medic Agenda',
            },
            HOSPITALIZATION: {
                TITLE: 'Hospitalization',
            },
            LOGOUT: {
                TITLE: 'Logout',
                MESSAGE: '¿Are you sure you want to logout?',
                YES: 'Yes',
                NO: 'No',
            },
            PATIENTS: {
                TITLE: 'Patients',
            },
            CALENDAR: {
                TITLE: 'Calendar',
            },
            PORTAL: {
                HOME: 'Main',
                PATIENT: 'Patients',
                ATTEND: 'Appointments',
                WAITING_LIST: 'Waiting list',
                REPORT: 'Reports',
                CONFIG: 'Configuration',
                CLINICS: 'Clinics',
                // Msg
                HOME_MSG: 'My main view',
                PATIENT_MSG: 'Locate my patients',
                ATTEND_MSG: 'See my agenda',
                WAITING_LIST_MSG: 'My list for telemedicine',
                REPORT_MSG: 'Reports from my patients',
                CONFIG_MSG: 'Settings to my configuration',
                CLINICS_MSG: 'List of clinics',
                MY_APPOINTMENTS: 'Appointments',
                MY_MEDICS: 'Doctors',
                MY_RECIPES: 'Recipes',
                MY_REPORTS: 'Reports',
                HISTORY: 'History',
                // Blocked
                HOME_BLOCKED:
                    'Not available, request the activation of the Telemedicine, Appointment Control or Medical History module.',
                PATIENT_BLOCKED:
                    'Not available, request the activation of the Telemedicine, Appointment Control or Medical History module.',
                ATTEND_BLOCKED:
                    'Not available, request the activation of the Appointment Control module.',
                WAITING_LIST_BLOCKED:
                    'Not available, request the activation of the Telemedicine module.',
                REPORT_BLOCKED:
                    'Not available, request activation of the Medical History module.',
                CONFIG_BLOCKED:
                    'Not available, request module activation: Telemedicine, Appointment Control or Medical History.',
            },
            SALES: {
                CLIENTS: 'Clients',
                INVENTORY: 'Items',
                TAX: 'Taxes',
                UNIT: 'Units',
            },
        },
        BTN: {
            CLOSE: 'Close',
            CONTINUE: 'Continuar',
            CANCEL: 'Cancel',
            DELETE: 'Delete',
            EXIT: 'Exit',
            CONTINUE_ATTENDING: 'Continue attending',
            SAVE_EXIT: 'Save and exit',
        },
        DIALOG: {
            MEDIC_APPOINTMENT_TITLE: 'Close appointment attention',
            LOSE_CHANGES:
                'You will lose all the changes applied. Do you wish to continue?',
            BODY: 'You are about to leave the attention of the current appointment. \nWhat action do you want to take?',
        },
        BLOCKED_MSG:
            'The trial period has expired, to subscribe to a plan contact us at ventas@portalmedico.com',
        TRIAL_ENDED: {
            TITLE: 'PM+ Subscription Plan',
            MSG_LINE1:
                'The trial period has ended, you must subscribe to a plan.',
            MSG_LINE2: 'Please contact',
            MSG_LINE3: 'to activate a plan.',

            SEND_MAIL: 'Send mail',
            FIRST_SUBHEADER:
                'Your trial period has ended, you need to subscribe to a plan.',
            MONTH_SUS: 'Monthly subscription',
            ADDITIONAL_MED: 'Additional medics',
            MORE_THAN: 'More than',
            MEDICS: 'medics',
            SUBSCRIBE: 'Subscribe',
            COMPARE_PLANS_SPECS: 'Compare plans and characteristics',
            SPECS: 'Characteristics',
            CONTACT: 'Contact',
            REQ_REC:
                "We've received your request, you'll be contacted by our sales department.",
            THANKS: 'Thanks!',
            MONTH: 'Month',
        },
        ERROR: {
            PATIENT_VIDEOCALL_DISCONNECTED:
                'A problem has occurred with the video call. The connection has been lost. Please try to connect again.',
            CHECK_CONNECTION:
                'Please check your connection, wait a moment and try again.',
        },
        ALREADY_CONNECTED:
            'There is already an user connected, please wait a few minutes and try to login again.',
    },
};
