import {
    Component,
    OnInit,
    Input,
    AfterViewChecked,
    OnDestroy,
    OnChanges,
    SimpleChanges,
    Output,
    EventEmitter,
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { HistoryService } from '../../history.service';
import {
    MatDialog,
    MatSnackBar,
    MatSnackBarHorizontalPosition,
    MatSnackBarVerticalPosition,
} from '@angular/material';
import { QuirurgicoService } from '../../quirurgico/quirurgico.service';
import { CitasService } from '../../citas/citas.service';
import { FuseConfigService } from '@fuse/services/config.service';
import { takeUntil } from 'rxjs/operators';
import { BotoneraAccionesMenuService } from './botonera-acciones-menu.service';
import { IngresoService } from '../../ingreso/ingreso.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-botonera_acciones_menu',
    templateUrl: './botonera_acciones_menu.component.html',
    styleUrls: ['./botonera_acciones_menu.component.scss'],
})
export class BotoneraAccionesMenu
    implements OnInit, AfterViewChecked, OnDestroy, OnChanges
{
    paciente_id: any;
    cita_id: any;
    om_id: any;
    model: any = {
        secciones: [],
    };
    validar = false;
    selectedTemplate: string;

    templates: any = [];
    appointmentType: string;
    activeType: string;
    selected: string;
    ruta: string;
    view: string;
    settings: any;
    templateName: string = '';

    hideMenu: boolean;
    toggleActivated: boolean;
    toggleText: boolean;

    private _unsubscribeAll: Subject<any> = new Subject();
    @Input() consulta_id: any;
    @Input() citaInfo: any;

    // constiables privadas
    public data: any;
    public horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    public verticalPosition: MatSnackBarVerticalPosition = 'top';

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private citasServ: CitasService,
        private _fuseConfigService: FuseConfigService,
        private historyService: HistoryService,
        private botoneraService: BotoneraAccionesMenuService,
        private ingresoService: IngresoService,
        private translate: TranslateService
    ) {
        this.paciente_id = this.route.snapshot.paramMap.get('paciente_id');
        this.cita_id = this.route.snapshot.paramMap.get('cita_id');
        this.om_id = this.route.snapshot.paramMap.get('om_id');
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((settings) => {
                this.settings = settings;
            });
    }
    ngOnInit(): void {
        this.ingresoService.model
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((model) => {
                this.setModelAndTemplate(model);
            });
        this.ruta =
            this.selected =
            this.view =
                this.route.snapshot.paramMap.get('view_id');

        if (this.view === null) {
            this.view = 'citas';
        }
    }

    ngOnChanges(simpleChanges: SimpleChanges): void {
        if (simpleChanges && simpleChanges.citaInfo.currentValue) {
            this.getTemplates();
            this.toggleActivated = this.citaInfo.nota_evolutiva;
            this.toggleText = this.citaInfo.nota_evolutiva;
            this.botoneraService.updateVariable(this.toggleText);
        }
    }

    ngAfterViewChecked(): void {
        if (!this.router.url.includes(this.view) && this.view !== 'citas') {
            this.ruta =
                this.selected =
                this.view =
                    this.router.url.split('/')[5];
        }
    }

    getTemplates(): void {
        this.historyService
            .getTemplates(this.settings.user.id, this.citaInfo.especialidad.id)
            .subscribe(
                (res) => {
                    this.templates = res.filter(
                        (ele) => ele.tipo === 'historia'
                    );
                },
                (err) => {
                    console.error(err);
                }
            );
    }

    toggleNotaEvolutiva(value: boolean, appointment: string): void {
        if (this.activeType !== appointment) {
            this.historyService
                .putNotaEvolutiva(this.cita_id, { status: value })
                .subscribe(
                    (res) => {
                        this.selectedTemplate = '';
                        this.appointmentType = appointment;
                        this.activeType = appointment;
                        this.toggleText = value;
                        this.botoneraService.updateVariable(this.toggleText);
                        this.botoneraService.updateTemplate(true);
                        // this.snackBar.open(res.message, '', {
                        //     verticalPosition: 'top',
                        //     panelClass: 'custom-blue',
                        //     duration: 3000,
                        // });
                    },
                    (err) => {
                        console.error(err);
                    }
                );
        }
    }

    toggleTemplate(template: any): void {
        if (this.selectedTemplate !== template.template_cita) {
            const templateBody = {
                template_cita: template.template_cita,
                cita: this.cita_id,
                definicion: { definicion: template.definicion },
            };
            this.historyService.postTemplatesCita(templateBody).subscribe(
                (res) => {
                    this.selectedTemplate = template.template_cita;
                    this.appointmentType = '';
                    this.activeType = '';
                    this.botoneraService.updateTemplate(true);
                    // this.snackBar.open(res.message, '', {
                    //     verticalPosition: 'top',
                    //     panelClass: 'custom-blue',
                    //     duration: 3000,
                    // });
                },
                (err) => {
                    console.error(err);
                }
            );
        }
    }

    changeSrc(e: any, itemId: string): void {
        let nodes;
        if (this.selectedTemplate !== itemId) {
            nodes = e.target.firstChild.firstChild.children;
            const src = nodes[0].src;

            if (src.includes('blanco')) {
                e.target.firstChild.firstChild.firstChild.src = src.replace(
                    'blanco',
                    'color'
                );
            } else {
                e.target.firstChild.firstChild.firstChild.src = src.replace(
                    'color',
                    'blanco'
                );
            }
        }
    }

    altaClass(): string {
        const viewLocation = this.route.snapshot.paramMap.get('view_id');
        return viewLocation === 'alta'
            ? 'mat-raised-button mat-accent estilizar-botones'
            : 'mat-raised-button mat-basic estilizar-botones';
    }

    notasOperatoriasClass(): string {
        const viewLocation = this.route.snapshot.paramMap.get('view_id');
        return viewLocation === 'notas_operatorias' ||
            viewLocation === 'detalles_notas_operatorias'
            ? 'mat-raised-button mat-accent estilizar-botones'
            : 'mat-raised-button mat-basic estilizar-botones';
    }

    laboratorioClass(): string {
        const viewLocation = this.route.snapshot.paramMap.get('view_id');
        return viewLocation === 'laboratorio' ||
            viewLocation === 'laboratorio_resultados'
            ? 'mat-raised-button mat-accent estilizar-botones'
            : 'mat-raised-button mat-basic estilizar-botones';
    }

    imagenesClass(): string {
        const viewLocation = this.route.snapshot.paramMap.get('view_id');
        return viewLocation === 'imagenes' || viewLocation === 'cargar_archivos'
            ? 'mat-raised-button mat-accent estilizar-botones'
            : 'mat-raised-button mat-basic estilizar-botones';
    }

    bodyContentClass(): string {
        // router  is an instance of Router, injected in the constructor
        const viewLocation = this.route.snapshot.paramMap.get('view_id');
        return viewLocation === 'listar_ordenes_medicas' ||
            viewLocation === 'ordenes_medicas_hospitalizacion' ||
            viewLocation === 'ordenes_medicas_hospitalizacion_editar' ||
            viewLocation === 'ordenes_medicas_dieta' ||
            viewLocation === 'ordenes_medicas_dieta_editar' ||
            viewLocation === 'ordenes_medicas_mezclas' ||
            viewLocation === 'ordenes_medicas_mezclas_editar' ||
            viewLocation === 'ordenes_medicas_tratamiento' ||
            viewLocation === 'ordenes_medicas_tratamiento_editar' ||
            viewLocation === 'ordenes_medicas_laboratorio' ||
            viewLocation === 'ordenes_medicas_laboratorio_editar' ||
            viewLocation === 'ordenes_medicas_imagenes' ||
            viewLocation === 'ordenes_medicas_imagenes_editar' ||
            viewLocation === 'ordenes_medicas_cirugia' ||
            viewLocation === 'ordenes_medicas_cirugia_editar' ||
            viewLocation === 'ordenes_medicas_interconsulta' ||
            viewLocation === 'ordenes_medicas_interconsulta_editar' ||
            viewLocation === 'ordenes_medicas_posicion' ||
            viewLocation === 'ordenes_medicas_posicion_editar' ||
            viewLocation === 'ordenes_medicas_otros' ||
            viewLocation === 'ordenes_medicas_otros_editar'
            ? 'mat-raised-button mat-accent estilizar-botones'
            : 'mat-raised-button mat-basic estilizar-botones';
    }

    changeContent(content): void {
        this.selected = content;
        if (this.view !== 'citas') {
            const paciente_id = this.route.snapshot.paramMap.get('paciente_id');
            const cita_id = this.route.snapshot.paramMap.get('cita_id');
            const consulta_id = this.route.snapshot.paramMap.get('consulta_id');
            const om_id = this.route.snapshot.paramMap.get('om_id');

            this.ruta = content;
            this.router.navigate([
                'medic/history/' +
                    paciente_id +
                    '/' +
                    cita_id +
                    '/' +
                    content +
                    '/' +
                    consulta_id +
                    '/' +
                    om_id,
            ]);
        } else {
            this.ruta = content;
            this.citasServ.changeSelected(content);
        }
    }

    setModelAndTemplate(model: any): void {
        this.model = model;
        const section =
            this.model.secciones && this.model.secciones.length > 0
                ? this.model.secciones.filter(
                      (ele) => ele.tipo === 'historia'
                  )[0]
                : undefined;

        this.selectedTemplate = this.model.secciones
            ? section
                ? section.template_cita
                : ''
            : '';

        if (this.selectedTemplate === '') {
            this.toggleText
                ? ((this.appointmentType = this.activeType = 'nota'),
                  (this.templateName = this.translate.instant(
                      'MENU_IZQUIERDO.CHANGE_TO_NOTE'
                  )))
                : ((this.appointmentType = this.activeType = 'historia'),
                  (this.templateName = this.translate.instant(
                      'MENU_IZQUIERDO.CHANGE_TO_APPOINTMENT'
                  )));
        } else {
            this.templateName =
                this.templates.filter(
                    (ele) => ele.template_cita === this.selectedTemplate
                )[0].descripcion || '';
            this.appointmentType = '';
            this.activeType = '';
        }
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
