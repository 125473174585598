import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
    ViewEncapsulation,
    Inject,
    OnDestroy,
} from '@angular/core';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { locale as english } from './i18n/en';
import { locale as spanish } from './i18n/es';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { AuthService } from 'app/main/auth/auth.service';
import { Router } from '@angular/router';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { FuseConfigService } from '@fuse/services/config.service';
import { PortalService } from 'app/main/portal/portal.service';
import { ConfirmationDialogComponent } from 'app/modules/shared/components/confirmation-dialog/confirmation-dialog.component';
import * as moment from 'moment';

@Component({
    selector: 'fuse-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FuseNavigationComponent implements OnInit {
    @Input()
    layout = 'vertical';

    @Input()
    navigation: any;

    // Private
    private _unsubscribeAll: Subject<any>;

    languages: any;
    selectedLanguage: any;
    fuseConfig: any;
    toggleOnline = false;

    /**
     *
     * @param {ChangeDetectorRef} _changeDetectorRef
     * @param {FuseNavigationService} _fuseNavigationService
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        private _translateService: TranslateService,
        private _fuseConfigService: FuseConfigService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private dialog: MatDialog,
        private portalService: PortalService
    ) {
        this.languages = [
            {
                id: 'en',
                title: 'English',
                flag: 'us',
            },
            {
                id: 'es',
                title: 'Español',
                flag: 'es',
            },
        ];

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Load the navigation either from the input or from the service
        this.navigation =
            this.navigation ||
            this._fuseNavigationService.getCurrentNavigation();

        // Subscribe to the current navigation changes
        this._fuseNavigationService.onNavigationChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                // Load the navigation
                this.navigation = this._fuseNavigationService.getCurrentNavigation();

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });

        this._fuseConfigService
            .getConfig()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((data) => {
                this.fuseConfig = data;
            });

        // Subscribe to navigation item
        merge(
            this._fuseNavigationService.onNavigationItemAdded,
            this._fuseNavigationService.onNavigationItemUpdated,
            this._fuseNavigationService.onNavigationItemRemoved
        )
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
        // Set the selected language from default languages
        this.selectedLanguage = _.find(this.languages, {
            id: this._translateService.currentLang,
        });

        const logout = this._fuseNavigationService.getNavigationItem('logout');
        const language = this._fuseNavigationService.getNavigationItem(
            'language'
        );
        if (logout === false) {
            const customFunctionNavItem = {
                id: 'logout',
                title: 'Desconectarse',
                type: 'item',
                translate: 'NAV.LOGOUT.TITLE',
                icon: 'exit_to_app',
                function: () => {
                    this.goLogout();
                },
            };
            this._fuseNavigationService.addNavigationItem(
                customFunctionNavItem,
                'account'
            );
        }

        if (language === false) {
            const customFunctionNavItem = {
                id: 'language',
                title: 'Language: EN',
                type: 'collapsable',
                translate: 'NAV.LANGUAGE.TITLE',
                icon: 'language',
                hidden: true, // Se ocultara mientras tanto
                children: [
                    {
                        id: 'en',
                        title: 'Inglés',
                        type: 'item',
                        translate: 'NAV.LANGUAGE.ENGLISH',
                        image: 'assets/icons/flags/us.png',
                        function: () => {
                            this.setLanguage({
                                id: 'en',
                                title: 'English',
                                flag: 'us',
                            });
                        },
                    },
                    {
                        id: 'es',
                        title: 'Español',
                        type: 'item',
                        translate: 'NAV.LANGUAGE.SPANISH',
                        image: 'assets/icons/flags/es.png',
                        function: () => {
                            this.setLanguage({
                                id: 'es',
                                title: 'Spanish',
                                flag: 'es',
                            });
                        },
                    },
                ],
            };
            this._fuseNavigationService.addNavigationItem(
                customFunctionNavItem,
                'account'
            );
        }
        this._fuseTranslationLoaderService.loadTranslations(english, spanish);

        // console.log(this.selectedLanguage)
    }

    goLogout() {
        if (this.fuseConfig.isMedic) {
            this.getListaEspera();
        } else {
            const dialog = this.dialog.open(LogoutDialogComponent, {
                panelClass: 'custom-dialog',
                autoFocus: false,
                data: {},
            });
        }
    }

    setLanguage(lang): void {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;

        // Use the selected language for translations
        this._translateService.use(lang.id);

        // Set selected language on localStorage
        localStorage.setItem('language', lang.id);
    }

    getListaEspera(): void {
        this.portalService
            .getListaEspera(
                this.fuseConfig.user.id,
                this.fuseConfig.user.ambulatorio[0].ambulatorio,
                moment().format('YYYY-MM-DD'),
                moment().format('YYYY-MM-DD')
            )
            .subscribe(
                async (res) => {
                    const filtered = res.filter((ele) => ele.cita === null);
                    if (filtered.length === 0 || this.toggleOnline === false) {
                        const dialog = this.dialog.open(LogoutDialogComponent, {
                            panelClass: 'custom-dialog',
                            autoFocus: false,
                            data: {},
                        });
                    } else {
                        const dialogRef = this.dialog.open(
                            ConfirmationDialogComponent,
                            {
                                width: '280px',
                                panelClass: 'custom-dialog',
                                autoFocus: false,
                                data: {
                                    text: this._translateService.instant(
                                        'WAITING_LIST.CONFIRM_DISCONNECT_MSG'
                                    ),
                                },
                            }
                        );
                        const sub = dialogRef
                            .afterClosed()
                            .subscribe(async (res) => {
                                if (res) {
                                    const dialog = this.dialog.open(
                                        LogoutDialogComponent,
                                        {
                                            panelClass: 'custom-dialog',
                                            width: '280px',
                                            data: {},
                                        }
                                    );
                                }
                                sub.unsubscribe();
                            });
                    }
                },
                (err) => {}
            );
    }
}

@Component({
    selector: 'logout-dialog',
    templateUrl: './logout-dialog/logout-dialog.component.html',
})
export class LogoutDialogComponent implements OnInit, OnDestroy {
    fuseConfig: any;

    private _unsubscribeAll = new Subject();
    constructor(
        private fuseConfigService: FuseConfigService,
        public dialogRef: MatDialogRef<any>,
        public authService: AuthService,
        private router: Router,
        private _fuseConfigService: FuseConfigService,
        private portalService: PortalService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit(): void {
        this.fuseConfigService
            .getConfig()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => (this.fuseConfig = config));
    }

    cancel(): void {
        this.dialogRef.close();
    }

    async goLogout(): Promise<any> {
        if (this.fuseConfig.isMedic) {
            const body = {
                id: this.fuseConfig.user.id,
                on_line: false,
            };
            await this.portalService.putMedicoStatus(body).toPromise();
        }

        this.authService.logout().subscribe(
            (data) => {
                localStorage.removeItem('authToken');
                sessionStorage.removeItem('authToken');
                this.router.navigate(['/auth/main']);
                this._fuseConfigService.resetToDefaults();
                this.dialogRef.close();
            },
            (err) => {
                // console.log(err);
                localStorage.removeItem('authToken');
                sessionStorage.removeItem('authToken');
                this.router.navigate(['/auth/main']);
                this.dialogRef.close();
            }
        );
        this._fuseConfigService.resetToDefaults();
    }

    /**
     * On destroy
     */
    async ngOnDestroy(): Promise<any> {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
