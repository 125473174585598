import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class QuirurgicoService {
    private hideOptions = new BehaviorSubject(false);
    showOption = this.hideOptions.asObservable();

    constructor() {
        this.hideOptions.next(false);
    }

    changeDisplay(bool: boolean): void {
        this.hideOptions.next(bool);
    }
}
