export const rolesNavigation = {
    medico: {
        id: 'medic_menu',
        title: 'Médico',
        translate: 'NAV.MEDIC_MENU',
        type: 'group',
        children: [
            {
                id: 'patients_of_the_day',
                title: 'Pacientes del Día',
                translate: 'NAV.PATIENTS_OF_THE_DAY.TITLE',
                type: 'item',
                icon: 'account_circle',
                url: '/medic/patients_of_the_day',
            },
            {
                id: 'presupuesto',
                title: 'Presupuesto',
                translate: 'NAV.PRESUPUESTO.TITLE',
                type: 'item',
                icon: 'ballot',
                url: '/presupuesto',
            },
            {
                id: 'honorarios',
                title: 'Honorarios',
                translate: 'NAV.HONORARIOS.TITLE',
                type: 'item',
                icon: 'account_balance_wallet',
                url: '/honorarios',
            },
            {
                id: 'agenda',
                title: 'Agenda',
                translate: 'NAV.AGENDA.TITLE',
                type: 'item',
                icon: 'calendar_today',
                url: '/agenda',
            },
        ],
    },
    admin: {
        id: 'admin_menu',
        title: 'Administrador',
        translate: 'NAV.ADMIN_MENU',
        type: 'group',
        children: [],
    },
    enfermeria: {
        id: 'nurse_menu',
        title: 'Enfermeria',
        translate: 'NAV.NURSE_MENU',
        type: 'group',
        children: [],
    },
};
