import { Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PacienteGuard implements CanActivate {
    constructor() {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | boolean {
        const view = next.paramMap.get('view_id');
        const consulta = next.paramMap.get('consulta_id');

        // DEV
        // return true;

        switch (parseInt(consulta)) {
            case 1:
            case 5:
                if (
                    view === 'ingreso' ||
                    view === 'alta' ||
                    view === 'citas' ||
                    view === 'agregar_alergia' ||
                    view === 'agregar_antecedentes' ||
                    view === 'agregar_signos_vitales'
                )
                    return true;
                return true;

            case 2:
                if (view === 'ingreso') return false;
                return true;

            default:
                return true;
        }
    }
}
