import { Injectable } from '@angular/core';
import { NetworkService } from 'app/services/network-service.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ThemeOptionsService {
    constructor(private networkService: NetworkService) {}

    /**
     * Actualizar layout de user.
     */
    putLayout(user_id: string, body: any): Observable<any> {
        return this.networkService.put(`users/${user_id}/layout`, body);
    }
}
