import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class CitasService {
    private selectedOption = new BehaviorSubject('');
    private citaId = new BehaviorSubject('');
    // utilizado para actualizar el historial de citas
    private updateHistoric = new BehaviorSubject('');

    option = this.selectedOption.asObservable();
    cita = this.citaId.asObservable();
    // historic = this.updateHistoric.asObservable();

    constructor() {
        this.selectedOption.next('');
        this.citaId.next('');
        // this.updateHistoric.next(false);
    }

    changeSelected(selectedOption: string): void {
        this.selectedOption.next(selectedOption);
    }

    changeId(cita_id: string): void {
        this.citaId.next(cita_id);
    }
}
